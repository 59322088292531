import Checkbox from "@components/Checkbox";
import Radio from "@components/Radio";
import { QuestionInterface, QuestionType } from "@network/Questions/type";
import React, { useRef, useState } from "react";
import * as S from "../style";
import Img from "@assets/img";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";

const QuestionContainer = ({
  currentStep,
  setCurrentStep,
  totalStep,
  question,
  index,
  selectedAnswers,
  setSelectedAnswers,
  handleChangeAnswer,
  onSubmitQuestionForm,
}: {
  totalStep: number;
  question?: QuestionInterface;
  index: number;
  selectedAnswers: string[];
  setSelectedAnswers: (selectedAnswers: string[]) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  onSubmitQuestionForm: () => void;
  handleChangeAnswer: (questionId: number, answers: string[]) => void;
}) => {
  const [currentAnswer, setCurrentAnswer] = useState<string[]>([]);
  const handleStep = (step: number) => {
    if (step !== totalStep) {
      setCurrentStep(step + 1);
      setCurrentAnswer([]);
    } else {
      onSubmitQuestionForm();
    }
  };

  const onChangeAnswer = (answer: string) => {
    if (question) {
      let selectAnswer = [...selectedAnswers];
      if (question.type === QuestionType.MULTIPLE_SELECT) {
        if (selectedAnswers.includes(answer)) {
          selectAnswer = selectAnswer.filter((a) => a !== answer);
        } else {
          selectAnswer.push(answer);
        }
      } else {
        selectAnswer = [answer];
      }
      setSelectedAnswers([...selectAnswer]);
      setCurrentAnswer([...selectAnswer]);
      handleChangeAnswer(question.id, selectAnswer);
    }
  };

  return (
    <S.AnswerGroupWrapper>
      <S.QuestionContainer>
        <S.Question>{`${index + 1}. ${question?.title}`}</S.Question>
        <S.AnswerGroup quantity={question?.answers?.length}>
          {question?.answers?.map((answer) => {
            const isChecked = selectedAnswers?.includes(answer.id);
            if (question.type === QuestionType.MULTIPLE_SELECT) {
              return (
                <Checkbox
                  checked={isChecked}
                  key={question.id}
                  label={answer.content}
                  name={question.id.toString()}
                  id={answer.id}
                  onChange={() => onChangeAnswer(answer.id)}
                />
              );
            }
            return (
              <div
                className="d-flex flex-column"
                key={`${answer.content}_${answer.id}`}
              >
                {answer?.metaData?.img?.url && (
                  <div
                    className="d-flex w-100"
                    style={{
                      height: 80,
                      marginBottom: 12,
                      paddingLeft: 20,
                      justifyContent: "center",
                    }}
                  >
                    <img
                      key={question.id}
                      src={answer?.metaData?.img?.url}
                      className="img-question"
                      width={130}
                      height={85}
                    />
                  </div>
                )}
                <Radio
                  checked={isChecked}
                  key={question.id}
                  label={answer.content}
                  name={question.id.toString()}
                  id={answer.id}
                  onChange={() => onChangeAnswer(answer.id)}
                />
              </div>
            );
          })}
        </S.AnswerGroup>
      </S.QuestionContainer>
      <S.AnswerAction>
        <S.AnswerImg src={Img?.Mascot_1} />
        <S.AnswerButton
          color={
            currentStep === totalStep
              ? "linear-gradient(to right, #2edcd3 0%, #2edcd3 100%)"
              : "linear-gradient(to right, #0097b2, #7ed957)"
          }
          onClick={() => handleStep(currentStep)}
          disabled={currentAnswer.length === 0}
        >
          {currentStep === totalStep ? "Submit" : "Next"}
        </S.AnswerButton>
        <S.AnswerImg src={DashboardConstant.WnnrLogo} />
      </S.AnswerAction>
    </S.AnswerGroupWrapper>
  );
};

export default QuestionContainer;
