import React, { useEffect, useState } from "react";

import Img from "@assets/img";
import RaffleSwiper from "@components/RaffleSwiper";
import { RAFFLE_TYPE } from "@const/dashboard";
import { RaffleKey, RaffleTitle } from "@const/profile";
import { SearchBy } from "@network/Raffle/type";
import { RaffleDataBased } from "@type/Raffle";
import { SCREEN } from "@type/index";
import * as S from "./style";

interface RaffleItemProps {
  raffleData?: RaffleDataBased;
  screen?: SCREEN;
  limit?: number;
  searchBy?: SearchBy;
  keyword?: string;
  filter?: string;
}

const RaffleSwiperRender: React.FC<RaffleItemProps> = ({
  screen,
  limit,
  searchBy,
  raffleData,
  keyword,
  filter,
}) => {
  const [raffleDataResult, setRaffleDataResult] = useState<RaffleDataBased>();
  let allEmpty = true;

  useEffect(() => {
    if (raffleData) {
      setRaffleDataResult(raffleData);
    }
  }, [raffleData]);

  return (
    <>
      {RaffleKey.map((item: string, key) => {
        const data = raffleDataResult?.raffles[item]?.raffles || [];
        if (data.length > 0) {
          allEmpty = false;
        }

        return (
          <RaffleSwiper
            key={key}
            initData={data}
            title={RaffleTitle[item]}
            type={RAFFLE_TYPE[item]}
            screen={screen ?? SCREEN.DASHBOARD}
            limit={limit ?? 5}
            searchBy={searchBy}
            keyword={keyword}
            filter={filter}
          />
        );
      })}
      {allEmpty && (
        <S.NoSelectionBg>
          <img src={Img.NoSelectionBg} alt="No data" width={350} />
          <p>No raffle displayed</p>
        </S.NoSelectionBg>
      )}
    </>
  );
};

export default RaffleSwiperRender;
