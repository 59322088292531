import instanceAuth from "@network/auth";
import { QuestionParams } from "./type";

const api = "/raffles";

export async function getMilestoneById(id: number) {
  const instance = instanceAuth();
  const res = await instance.get(`${api}/${id}/questions`);

  return res.data;
}

export async function submitAnswers(payload: QuestionParams) {
  const instance = instanceAuth();
  const res = await instance.post(`/user-answers`, payload);

  return res.data;
}
