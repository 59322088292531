import Img from "@assets/img";
import { Button } from "@components/Button";
import Table from "@components/Table";
import { getRaffleById } from "@network/Raffle";
import { RaffleResultResData } from "@network/Raffle/type";
import { SCREEN } from "@type/index";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as S from "./style";
import { routes } from "@const/routes";

const dataHeader = ["No", "Username", "Phone number", "Lucky number", "Reward"];

export const RaffleResultDetail = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [raffleResult, setRaffleResult] =
    useState<RaffleResultResData["data"]>();

  const raffleId = parseInt(id as string);

  const tableData = useMemo(() => {
    return raffleResult?.winners.map((winner) => {
      const { turn, username, phoneNumber, luckyNumber, award } = winner;
      return {
        turn,
        username,
        phoneNumber,
        luckyNumber,
        award,
      };
    });
  }, [raffleResult]);

  useEffect(() => {
    const fetchRaffleById = async (raffleId: number) => {
      try {
        const res: RaffleResultResData = await getRaffleById(raffleId, {
          screen: SCREEN.RAFFLE_RESULT,
        });
        setRaffleResult(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRaffleById(raffleId);
  }, []);

  return (
    <>
      <Button
        className="hidden"
        icon={<img src={Img.BackButton} />}
        onClick={() => navigate(routes.raffleResult)}
      >
        Back to list
      </Button>
      <S.Title className="raffle_result_title">{title}</S.Title>
      <S.SubTitle>
        Time: {moment(raffleResult?.startTime).format("DD/MM/YYYY, HH:mm:ss")}
      </S.SubTitle>
      <Table
        dataHeader={dataHeader}
        dataRows={tableData ?? []}
        hidePagination
      />
    </>
  );
};
