import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative !important;

  .datetime-label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding: 10px 12px;
    font-size: 14px;
    outline: none;
    border: 1px solid #6ee2f54d !important;
    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #b3b3b3;
    }

    &[type="text"] {
      background: linear-gradient(
        to top,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      ) !important;
      box-shadow: 0px 2px 0px 0px #0000000b !important;
      color: #fff !important;
    }
  }

  .input-datetime-wrap {
    position: relative;

    .input-datetime {
      margin-bottom: 20px;
    }

    .input-calendar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: transparent !important;
      color: transparent !important;
      z-index: 1;
    }

    .input_datetime_icon {
      position: absolute;
      top: 20px;
      right: 17px;
      transform: translateY(-50%);
    }
  }
`;

export const DateTimePickerInput = styled.input`
  position: relative;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 200px;
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    height: auto;
    width: auto;
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: transparent;
    background: transparent;
  }
`;
