import styled from "styled-components";

export const ModalContent = styled.div`
  gap: 20px;
  padding: 15px 20px;
  width: 460px;

  .refer-link {
    border: 1px solid var(--border-text, rgba(110, 226, 245, 0.3));
    border-radius: 6px;
    padding: 5px 12px;
    margin-top: 16px;
    background: linear-gradient(
      341.1deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );

    p {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
      color: white;
    }
  }
  .refer-footer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
  }
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;

  &.justify-around {
    justify-content: space-around;
  }
`;

export const Item = styled.li`
  a {
    text-decoration: none;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    margin-top: 4px;
    color: #b3b3b3;
    font-size: 12px;
    line-height: 16px;
  }
`;
