const CONFIG = {
  API_URL: process.env.API_URL,
  SECRET_KEY_STORAGE: process.env.SECRET_KEY_STORAGE,
  KEY_USER_STORAGE: "CURRENT_USER",
  KEY_TOKEN_STORAGE: "persist:ROOT",
  FACEBOOK_SHARE_LINK: "https://www.facebook.com/sharer/sharer.php?u=",
  X_SHARE_LINK: "https://twitter.com/intent/tweet?url=",
  WHATSAPP_SHARE_LINK: "https://api.whatsapp.com/send?phone=&text=",
  INSTAGRAM: "https://www.instagram.com/wnnr.in?igsh=M2E5MnZ6aW1jNG9i",
};

export default CONFIG;
