import { Dispatch } from 'redux';

export interface NoticeReducerType {
	title?: string | null;
	content?: string | null;
	type: 'ERROR' | 'SUCCESS' | 'WAITING' | null;
}

export enum ActionType {
	UPDATE_NOTICE = 'UPDATE_NOTICE',
	CLEAR_NOTICE = 'CLEAR_NOTICE',
}

const initialState: NoticeReducerType = {
  title: null,
  content: null,
  type: null,
};

export interface NoticeActionType {
	type: ActionType;
	payload: NoticeReducerType;
}

export const clearNotice = () => ({
  type: ActionType.CLEAR_NOTICE,
  payload: initialState,
});

export const updateNotice = (notice: NoticeReducerType, dispatch: Dispatch): any => {
  dispatch({
    type: ActionType.UPDATE_NOTICE,
    payload: notice,
  });

  setTimeout(() => {
    dispatch(clearNotice());
  }, 5000);
};

const NoticeReducer = (state = initialState, action: NoticeActionType) => {
  switch (action.type) {
    case ActionType.UPDATE_NOTICE:
      return { ...state, ...action.payload };
    case ActionType.CLEAR_NOTICE:
      return initialState;
    default:
      return state;
  }
};

export default NoticeReducer;
