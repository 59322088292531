import Img from "@assets/img";
import { routes } from "./routes";

export const SOURCE = {
  COMPLETE_PROFILE: "COMPLETE_PROFILE",
  CLICK_WIDGET: "CLICK_WIDGET",
  ANSWER_QUESTION: "ANSWER_QUESTION",
  INVITE_FRIEND: "INVITE_FRIEND",
  CLICK_INVITE_LINK: "CLICK_INVITE_LINK",
};

export const RaffleStatus = {
  AVAILABLE: "AVAILABLE",
  PLAYING: "PLAYING",
  DONE: "DONE",
};

export const RaffleType = {
  daily: "DAILY",
  weekly: "WEEKLY",
  monthly: "MONTHLY",
  quarterly: "QUARTERLY",
  halfYear: "HALF_YEAR",
  annually: "ANNUALLY",
  special: "SPECIAL",
};

export const TicketType = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  HALF_YEAR: "HALF_YEAR",
  ANNUALLY: "ANNUALLY",
};

export const TicketStatus = {
  AVAILABLE: "AVAILABLE",
  USED: "USED",
  REGISTERED: "REGISTERED",
  EXPIRED: "EXPIRED",
};
export const GENDER = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
};

export const TicketSource = {
  COMPLETE_PROFILE: "COMPLETE_PROFILE",
  CLICK_WIDGET: "CLICK_WIDGET",
  ANSWER_QUESTION: "ANSWER_QUESTION",
  INVITE_FRIEND: "INVITE_FRIEND",
  CLICK_INVITE_LINK: "CLICK_INVITE_LINK",
};

export const TicketScreen = {
  MY_TICKET_LIST: "MY_TICKET_LIST",
  ADD_LUCKY_NUMBER: "ADD_LUCKY_NUMBER",
};

export const DataSideBar = [
  {
    id: 1,
    title: "Profile Management",
    link: routes.profile,
    logo: Img.userLogo,
  },
  {
    id: 2,
    title: "My Ticket",
    link: routes.ticket,
    logo: Img.walletLogo,
  },
  {
    id: 3,
    title: "My Favorite Raffle",
    link: routes.favoriteRaffle,
    logo: Img.heartLogo,
  },
];

export const TimeKey = ["issuedDate", "expiredDate", "usedTime"];

export const ObjKey = [
  "id",
  "title",
  "type",
  "source",
  "issuedDate",
  "expiredDate",
  "status",
  "usedTime",
  "raffle",
  "luckyNumber",
];

export const RaffleKey = [
  "daily",
  "weekly",
  "monthly",
  "quarterly",
  "halfYear",
  "annually",
  "special",
];

export const RaffleTitle = {
  daily: "Daily Games",
  weekly: "Weekly Games",
  monthly: "Monthly Games",
  quarterly: "Quarterly Games",
  halfYear: "Half Year Games",
  annually: "Yearly Games",
  special: "Special Games",
};
