import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 16px;
  width: fit-content;
  max-width: 525px;
  height: fit-content;
`;

export const SelectDropdown = styled.div`
  width: 100%;
  margin: 20px 0;
  h1 {
    margin: 0;
    display: flex;
    justify-content: start;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: white;
  }
  .calender-icon {
    position: absolute;
    top: 4px;
    right: 0px;
  }
  .css-t3ipsp-control {
    background-color: transparent;
    color: #fff;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-13cymwt-control {
    border: none;
    border-radius: 6px;
    background: linear-gradient(
      341.1deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
  }
  .select__value-container {
    padding: 0;
    .select__multi-value__label > div {
      font-size: 12px;
      font-weight: 400;
      background: linear-gradient(114deg, #00ffed 0%, #00b8ba 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      > div:last-child {
        display: none;
      }
    }
  }
  .css-3w2yfm-ValueContainer {
    padding: 0 12px;
    height: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
  .css-qbdosj-Input {
    cursor: pointer;
    color: #b3b3b3;
  }
  .css-d7l1ni-option {
    cursor: pointer;
    background: linear-gradient(
      341deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
  }
  .css-1nmdiq5-menu {
    background-color: #000;
    background: radial-gradient(
      79.92% 50.55% at 70.55% 52.33%,
      #000041 0%,
      #000030 49.5%,
      #00002a 100%
    );
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .basic-multi-select {
    outline: none;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    > input {
      background: linear-gradient(
        to top,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      );
    }
    .select__input-container {
      color: white;
    }
    .css-1p3m7a8-multiValue {
      height: 30px;
      border-radius: 6px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      gap: 12px;
      background: linear-gradient(
        341.1deg,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      );
    }
    .css-wsp0cs-MultiValueGeneric {
      font-size: 12px;
      font-weight: 400;
      background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      div div {
        font-size: 14px;
        font-weight: 600;
        &:last-child {
          display: none;
          color: #E6E6E6;
          font-size: 0.8em;
        }
      }
    }
    .css-12a83d4-MultiValueRemove {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    }
    .css-1n6sfyn-MenuList {
      padding: 4px 0;
      border-radius: 8px;
      background: linear-gradient(113.96deg, #3499ff 0%, #3a3985 100%);
      box-shadow: 0px 9px 28px 8px #0000000d;
      box-shadow: 0px 6px 16px 0px #00000014;
      box-shadow: 0px 3px 6px -4px #0000001f;
    }
    .select__option {
      color: white;
      font-size: 14px;
      font-weight: 400;
      padding: 5px 12px;
    }
    .select__option--is-focused {
      background: linear-gradient(113.96deg, #64e8de 0%, #8a64eb 100%);
    }
    .select__value-container {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .css-1jqq78o-placeholder {
      color: #fff;
      font-size: 14px;
    }
    .css-16xfy0z-control {
      background: linear-gradient(
        to top,
        rgba(110, 226, 245, 0.3) -28.66%,
        rgba(100, 84, 240, 0.3) 87.23%
      );
      color: #fff;

      border: 1px solid #6ee2f54d;
    }
  }
`;

export const TicketWrapper = styled.div`
  h1 {
    margin: 0;
    display: flex;
    justify-content: start;
    font-size: 16px;
    font-weight: 700;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const SelectWrapper = styled.div``;

export const SelectOptions = styled.div`
  display: flex;
  margin: 16px 0;
  gap: 16px;
`;

export const SelectOption = styled.div`
  display: flex;
  gap: 8px;
  h2 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const NumberList = styled.div`
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  column-gap: 8px;
  row-gap: 4px;
  width: fit-content;
  max-height: 475px;
  @media (max-width: 768px) {
    column-gap: 4px;
  }
  .available {
    border: 1px solid #6ee2f54d;
    color: #ebebeb;
  }
  .unavailable {
    color: #7f7f7f;
    background: linear-gradient(180deg, #4b6cb7 0%, #182848 100%);
    border: 1px solid #6ee2f54d;
    cursor: not-allowed;
  }
  .selected {
    color: #ebebeb;
    background: linear-gradient(113.96deg, #7bf2e9 0%, #b65eba 100%);
  }
`;

export const Number = styled.button`
  width: 44px;
  height: 44px;
  color: white;
  border: none;
  background: none;
  border-radius: 6px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
`;

export const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  button {
    border: none;
    /* background: none; */
    font-size: 16px;
    font-weight: 700;
    width: fit-content;
    min-width: 150px;
    height: fit-content;
    padding: 9px;
  }
  .random-btn {
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #00ffed;
    border-radius: 6px;
    box-shadow: 0px 2px 0px 0px #0000000b;
  }
  .select-btn {
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    border: 1px solid #00ffed;
    border-radius: 6px;
    box-shadow: 0px 2px 0px 0px #0000000b;
    color: white;
    cursor: pointer;
    :disabled {
      cursor: not-allowed;
    }
  }
`;

export const NoSelection = styled.div`
  margin-top: 16px;
  padding: 0px 30px 260px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 14px;
    font-weight: 400;
    color: white;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavigationButton = styled.div`
  margin: auto;
  display: flex;
  gap: 16px;
  padding-top: 16px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
`;

export const RemoveTag = styled.span`
  display: inline-block;
  height: 12px;
  width: 12px;
  font-size: 10px;
  line-height: 12px;
  background-color: #fff;
  border-radius: 50%;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  color: #000;
  text-align: center;
  cursor: pointer;
`;

export const SelectBox = styled.div`
  text-align: left;
`;

export const Note = styled.em`
  text-align: center;
  font-size: 0.8em;
  color: #fff;
  display: block;
  margin: 15px 0px 0px;
  span {
    color: red;
  }
`;
