import React from "react";
import { Container, Row, Col } from "reactstrap";

import Img from "@assets/img";

function SectionTypography() {
  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3>Typography</h3>
        </div>
        <div id="typography">
          <Row>
            {/* Typography elements here */}
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            <div className="title">
              <h3>Images</h3>
            </div>
            <Row>
              <Col md="3" sm="6">
                <h4 className="images-title">Rounded Image</h4>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={Img.UrielSoberanes}
                />
                {/* Image details */}
              </Col>
              <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Circle Image</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={Img.KaciBaum2}
                />
                <p className="text-center">Brigitte Bardot</p>
              </Col>
              <Col className="mr-auto" md="2" sm="3">
                <h4 className="images-title">Thumbnail</h4>
                <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={Img.ErikLucatero2}
                />
                <p className="text-center">John Keynes</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default SectionTypography;
