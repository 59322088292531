import { useState, useEffect, useRef, MutableRefObject } from "react";

/**
 * @param {boolean} initialIsVisible - Initial visibility state of the component.
 * @returns {object} An object containing:
 * - `ref`: A mutable reference object to the component.
 * - `isComponentVisible`: A boolean indicating if the component is visible.
 * - `setIsComponentVisible`: A function to update the visibility state.
 */

export default function useComponentVisible(initialIsVisible: boolean): {
  ref: MutableRefObject<HTMLDivElement | null>;
  isComponentVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
} {
  const [isComponentVisible, setIsComponentVisible] =
    useState<boolean>(initialIsVisible);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
