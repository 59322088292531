import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import * as S from "./style";

export interface TabBarProps {
  tabData?: any;
}
export interface TabBar {
  id: number;
  title: string;
  link: string;
  logo: string;
}

const TabSide = ({ tabData }: TabBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const crumbs = "/" + location.pathname.split("/").pop();

  return (
    <S.TabProfile>
      <S.TabContainer>
        {tabData?.map((element: TabBar) => (
          <div
            key={element.id}
            onClick={() => navigate(element.link)}
            className={crumbs === element.link ? "active" : ""}
          >
            <p>{element.title}</p>
          </div>
        ))}
      </S.TabContainer>
    </S.TabProfile>
  );
};

export default TabSide;
