export enum SCREEN {
  DASHBOARD = "DASHBOARD",
  VIEW_LUCKY_NUMBER = "VIEW_LUCKY_NUMBER",
  FAVORITE = "FAVORITE",
  RAFFLE_RESULT = "RAFFLE_RESULT",
  SELECT_LUCKY_NUMBER = "SELECT_LUCKY_NUMBER",
  ADD_LUCKY_NUMBER = "ADD_LUCKY_NUMBER",
}

export enum USER_ANSWER_FOR {
  RAFFLE = "RAFFLE",
}

export enum VERIFY_LINK_TYPE {
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_LOGIN_FROM_GIFT_LINK = "VERIFY_LOGIN_FROM_GIFT_LINK",
}
