import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import * as S from "./style";
import { Link, useLocation } from "react-router-dom";

const BreadcrumbNav = () => {
  const location = useLocation();
  let currentLink = "";

  let crumbs = location.pathname.split("/");
  crumbs.shift();
  if (crumbs[0] === "raffle") crumbs = ["dashboard", ...crumbs];

  const crumbsUppercase = crumbs.map((crumb) => {
    currentLink += `/${crumb}`;
    const crumbUppercase = convertString(crumb);
    return (
      <BreadcrumbItem
        key={crumb}
        tag={Link}
        to={currentLink}
        active={currentLink === location.pathname}
        className="breadcrumb-item"
      >
        {crumbUppercase}
      </BreadcrumbItem>
    );
  });

  return (
    <S.Wrapper>
      <div className="wrapper">
        <div className="container">
          <Breadcrumb>
            <BreadcrumbItem tag={Link} to={"/"} active={currentLink === "/"}>
              Home
            </BreadcrumbItem>
            {crumbsUppercase}
          </Breadcrumb>
        </div>
      </div>
    </S.Wrapper>
  );
};

function convertString(inputString: string) {
  return inputString.replace(/(^|-)([a-z])/g, function (match, p1, p2) {
    return (p1 && " ") + p2.toUpperCase();
  });
}

export default BreadcrumbNav;
