import React from "react";
import * as S from "./style";
import { ButtonType } from "./type";

export const Button = ({ children, icon, ...rest }: ButtonType) => {
  return (
    <S.Button {...rest}>
      {icon && <div className="icon_wrapper">{icon}</div>}
      {children}
    </S.Button>
  );
};
