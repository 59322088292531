export const API_END_POINTS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  RAFFLE_PARTICIPANTS: "/raffle",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  TICKETS: "/ticket",
  FAVORITE_RAFFLE: "/favorite-raffle",
  RAFFLE_RESULT: "/raffle-result",
  PRIZE_REDEMPTION: "/prize-redemption-guidance",
};
