import { AES, enc } from "crypto-js";
import { ClassConstructor, plainToInstance } from "class-transformer";

export class EncryptService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  decryptUseAES<T>(
    encryptedData: string,
    privateKey: string,
    cls: ClassConstructor<T>
  ): T {
    const decodedData = decodeURIComponent(encryptedData);
    const bytes = AES.decrypt(decodedData, privateKey);
    return plainToInstance(cls, JSON.parse(bytes.toString(enc.Utf8)));
  }
}
