export interface AppReducerPayload {
  isShowReferPopup: boolean;
}

export enum EAppAction {
  SHOW_REFER_POPUP = "SHOW_REFER_POPUP",
}

const initialState: AppReducerPayload = {
  isShowReferPopup: false,
};

export interface TAppAction {
  type: EAppAction;
  payload: AppReducerPayload;
}

const AppReducer = (state = initialState, action: TAppAction) => {
  switch (action.type) {
    case EAppAction.SHOW_REFER_POPUP:
      return { ...state, isShowReferPopup: action.payload };
    default:
      return state;
  }
};

export default AppReducer;
