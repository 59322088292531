import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as S from "./style";
import Img from "src/assets/img";
import { Button } from "@components/Button";
import { VARIANTS } from "@components/Button/type";
import classNames from "classnames";

type ButtonType = {
  title: string;
  onClick: () => void;
  timer?: number;
};

export interface AlertPopupProps {
  isOpen: boolean;
  header: string | React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  icon: any;
  onDismiss?: () => void;
  rightButton?: ButtonType;
  leftButton?: ButtonType;
  highLightContent?: boolean;
}

const AlertPopup = ({
  isOpen,
  header,
  title,
  description,
  icon,
  rightButton,
  leftButton,
  highLightContent,
  onDismiss,
}: AlertPopupProps) => {
  const [time, setTime] = useState<number>();

  useEffect(() => {
    if (leftButton && leftButton.timer) {
      setTime(leftButton.timer);
    }
  }, [leftButton]);

  useEffect(() => {
    if (time === 0 && onDismiss) {
      onDismiss();
    }

    if (time) {
      const timerId = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [time]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <S.ModalOverlay>
      <S.ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
        <S.Modal>
          <S.ModalHeader className={"header"}>
            <S.Title>{header}</S.Title>
            <S.Close onClick={onDismiss}>
              <img src={Img.closeLogo} alt="Logo" width={32} height={"auto"} />
            </S.Close>
          </S.ModalHeader>
          <S.ModalContainer>
            <S.ModalContent className={"center"}>
              <p
                className={classNames(
                  "description",
                  highLightContent && "highlight"
                )}
              >
                {title}
              </p>
              <img src={icon} alt="fire" width={132} />
              {description && (
                <p
                  className={classNames(
                    "sub_description",
                    highLightContent && "highlight"
                  )}
                >
                  {description}
                </p>
              )}
            </S.ModalContent>
            <S.FooterModal>
              {leftButton && (
                <Button
                  onClick={time ? onDismiss : leftButton?.onClick}
                  variant={VARIANTS.OUTLINE}
                >
                  {time ? `Close in ${time}s...` : leftButton?.title}
                </Button>
              )}
              {rightButton && (
                <Button
                  onClick={rightButton.onClick}
                  variant={VARIANTS.PRIMARY}
                >
                  {rightButton.title}
                </Button>
              )}
            </S.FooterModal>
          </S.ModalContainer>
        </S.Modal>
      </S.ModalWrapper>
    </S.ModalOverlay>,
    document.body
  );
};

export default AlertPopup;
