import styled from "styled-components";
import Img from "@assets/img";

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
  background-image: url(${Img.bgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    background-image: url(${Img.bgMobile});
  }
  .activeBg {
    background: #101355;
  }
`;

export const ContainerNavigate = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 99;
    display: block;
    padding-bottom: 5px;
  }
`;
export const TabProfile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ContainerWrapper = styled.div`
  width: calc(100% - 240px);
  padding: 15px 40px;
  display: flex;
  flex-direction: column;
  .title {
    margin: 0px 0 30px;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .hidden {
      display: none;
    }
    .active {
      display: block;
    }
  }
`;
