import styled from "styled-components";
import "../../styles/constants";
import { sm } from "../../styles/constants";

export const Content = styled.div`
  width: 100%;
  margin-bottom: 20px;
  .note_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: white;
    &-red {
      color: red;
    }
  }
`;

export const QuestionContainer = styled.div`
  margin-bottom: 20px;
  width: 70%;
`;

export const Question = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left !important;
  color: white;
  margin-bottom: 4px;
  flex: 1;
  word-wrap: break-word;
`;

export const AnswerGroup = styled.div<{ quantity?: number }>`
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${({ quantity }) =>
    `repeat(${quantity || 4}, minmax(0, 1fr))`};

  @media (max-width: ${sm}) {
    gap: 10px 0;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const AnswerGroupWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
`;
export const AnswerAction = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const AnswerImg = styled.img`
  margin: 0 auto;
  max-width: 90px;
`;
export const AnswerButton = styled.button<{ color?: string }>`
  border: none;
  width: 60%;
  margin: 10px auto 20px auto;
  background: ${({ color }) => color || "black"};
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 40px;
    color: #fff;
  }
`;

export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 500px;
`;

export const JoinQuizWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const JoinQuizImg = styled.img`
  display: flex;
  max-width: 170px;
  @media (max-width: 768px) {
    max-width: 140px;
    max-height: 120px;
  }
`;

export const QuizLogo = styled.img`
  display: flex;
  max-width: 170px;
  @media (max-width: 768px) {
    max-width: 100px;
  }
`;

export const JoinQuizTitle = styled.span`
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
  color: #f75e48;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const CongratulationTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #abca46;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const NotificationTitle = styled.div`
  color: white;
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const NotificationDes = styled.div`
  font-size: 20px;
  color: orange;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Button = styled.button<{ color?: string }>`
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin-top: 16px;
  color: white;
  border: none;
  padding: 5px 10px;
  background: ${({ color }) => color || "white"};
  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 8px;
  }
`;

export const NotificationWarning = styled.div`
  display: flex;
  margin-top: 16px;
  font-size: 12px;
  color: white;
`;

export const NotificationWarningText = styled.div`
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

export const WarningSymbol = styled.div`
  color: red;
  font-size: 20px;
  display: inline-block;
  line-height: 24px;
  @media (max-width: 768px) {
    line-height: 14px;
  }
`;
