import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  button {
    border: none;
  }
  .active {
    background: #101355;
  }
  .none {
    display: none;
  }
  .display {
    display: block;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 16px 16px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 16px !important;
  }
`;
export const Hamburger = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffffff33;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Close = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ffffff33;
  position: absolute;
  right: 16px;
  top: 17px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavUserContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const UserNotification = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    button:hover {
      background-color: transparent !important;
    }
    .nv-bellIcon {
      color: white;
      width: 100px !important;
      height: auto !important;
    }
  }
`;
export const NaviMoblie = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  background: radial-gradient(
    50.55% 79.92% at 70.55% 52.33%,
    #000041 0%,
    #000030 49.5%,
    #00002a 100%
  );
`;
export const Content = styled.div`
  padding: 80px 24px 0 24px;
  width: 100%;
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      margin-top: 8px;
    }
  }
  p {
    color: #7f7f7f;
    font-weight: 400;
    margin-bottom: 16px;
    &:hover {
      color: #fff;
    }
  }
`;
export const User = styled.div`
  position: absolute;
  bottom: 40px;
  left: 24px;
  p {
    color: #7f7f7f;
    font-weight: 400;
    margin-bottom: 16px;
    &:hover {
      color: #fff;
    }
  }
`;

export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 40px;
    color: #fff;
  }
`;
