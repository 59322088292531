import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Link)`
  text-decoration: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  border-radius: 10px;
  border: 1px solid;
  border-image-source: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  cursor: pointer;
`;

export const Image = styled.div<{ src: string }>`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  object-fit: cover;
  background-image: url(${(p) => p.src});
  background-size: cover;
  background-position: center;
`;

export const Title = styled.h2`
  text-wrap: nowrap !important;
`;
export const Info = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: 4px;
  div {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 300;
    span {
      font-size: 14px;
      color: #fff;
      font-weight: 200;
    }
  }
  @media (max-width: 426px) {
    h2 {
      font-size: 12px;
      display: flex;
      span {
        font-size: 12px;
        font-weight: 200;
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  h2 {
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #66eff1;
  }
  .waiting {
    -webkit-text-fill-color: #f9c74f;
  }
  button {
    background: none;
    border: none;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 426px) {
    h2 {
      font-size: 12px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  font-size: 16px;
  margin-top: 8px;
  h1 {
    margin: 0px !important;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px !important;
    font-weight: 700;
  }
`;
