import { configureStore, createAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import AppReducer from "./appReducer";
import authReducer from "./authReducer";
import noticeReducer from "./noticeReducer";

export const clearState = createAction<void>("clearState");

const clearStateReducer = (state: RootState, action: any) => {
  if (action.type === clearState.type) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const rootReducer = combineReducers({
  auth: authReducer,
  notice: noticeReducer,
  app: AppReducer,
});

const persistConfig = {
  key: "ROOT",
  storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(
  persistConfig,
  clearStateReducer as any
);

const store = configureStore({
  reducer: persistedReducer,
});
const persistor = persistStore(store);

export const clearStateAndPersist = () => {
  store.dispatch(clearState());

  persistor.purge().then(() => {
    console.log("All state and persist data cleared");
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export { persistor, store };
