import React from "react";
import ShareFriendPopup from "../ShareRafflePopup";

const ShareRaffle = ({
  isOpen,
  url,
  setIsOpen,
}: {
  isOpen: boolean;
  url: string;
  setIsOpen: () => void;
}) => {
  return <ShareFriendPopup isOpen={isOpen} url={url} setIsOpen={setIsOpen} />;
};

export default ShareRaffle;
