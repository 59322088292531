import styled from "styled-components";

export const SwiperWrapper = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
  }
  .swiper {
    position: relative;
    @media (min-width: 768px) {
      margin: 0px -20px;
      padding: 0px 20px;
    }
  }
  .swiper-button-next {
    background-image: url({Img});
    border: none;
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    cursor: pointer;
    button {
      border: none;
      background: none;
    }
  }
  .swiper-button-prev {
    border: none;
    position: absolute;
    top: 50%;
    left: 0px;
    z-index: 10;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    cursor: pointer;
    button {
      border: none;
      background: none;
      overflow: auto;
    }
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }
  .swiper-button-disabled {
    display: none;
  }
`;

export const ChevronLeft = styled.button`
  border: none;
  position: absolute;
  top: 50%;
  left: -20px;
  z-index: 10;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  cursor: pointer;
  button {
    border: none;
    background: none;
    overflow: auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ChevronRight = styled.button`
  border: none;
  position: absolute;
  top: 50%;
  right: -20px;
  z-index: 10;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
  cursor: pointer;
  button {
    border: none;
    background: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NoSelectionBg = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 14px;
    margin-top: 15px;
  }
`;
