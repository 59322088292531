export interface AuthState {
  token: string | null;
  user: any | null;
}

export enum AuthActionType {
  UPDATE_USER = "UPDATE_USER",
}

export interface AuthReducerType {
  type: AuthActionType;
  payload: {
    token: string;
    user: object;
  };
}

export const updateUser = (userData: object) => ({
  type: AuthActionType.UPDATE_USER,
  payload: userData,
});

const initialState: AuthState = {
  token: null,
  user: null,
};

const authReducer = (state = initialState, action: AuthReducerType) => {
  const { type, payload } = action;

  switch (type) {
    case AuthActionType.UPDATE_USER:
      return { ...state, ...payload };

      break;
    default:
      return state;

      break;
  }
};

export default authReducer;
