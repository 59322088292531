import React from "react";
import ReactDOM from "react-dom";

import classNames from "classnames";
import Img from "src/assets/img";
import * as S from "./style";
// import { ReactComponent as IconClose } from "assets/icon/close.svg";

export enum SizeType {
  SMALL = "SMALL",
  LARGE = "MEDIUM",
}

export interface ModalProps {
  visible: boolean;
  onOk?: () => void;
  onCancel: () => void;
  onBack?: () => void;
  title?: any;
  children?: any;
  footer?: boolean;
  okText?: string;
  cancelText?: string;
  checkBack?: boolean;
  size?: SizeType;
  isOnlyOkButton?: boolean;
  isDisabledOk?: boolean;
}

const PopUp = ({
  visible,
  onOk,
  onCancel,
  title,
  children,
  footer,
  okText,
  cancelText,
  onBack,
  checkBack,
  size = SizeType.SMALL,
  isOnlyOkButton,
  isDisabledOk,
}: ModalProps) => {
  if (!visible) return null;

  return ReactDOM.createPortal(
    <S.ModalOverlay>
      <S.ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
        <S.Modal
          className={classNames(
            "height-default",
            size === SizeType.LARGE && "w-large"
          )}
        >
          <S.ModalHeader className={"header"}>
            <S.Title>{title}</S.Title>
            <S.Close onClick={onCancel}>
              <img src={Img.closeLogo} alt="Logo" width={32} height={"auto"} />
            </S.Close>
          </S.ModalHeader>
          <S.ModalContainer>
            <S.ModalContent className={"center"}>{children}</S.ModalContent>
            {footer && (
              <PopupFooter
                onOk={onOk}
                onCancel={onCancel}
                okText={okText}
                cancelText={cancelText}
                onBack={onBack}
                checkBack={checkBack}
                isOnlyOkButton={isOnlyOkButton}
                isDisabledOk={isDisabledOk}
              />
            )}
          </S.ModalContainer>
        </S.Modal>
      </S.ModalWrapper>
    </S.ModalOverlay>,
    document.body
  );
};

const PopupFooter = ({
  onOk,
  onCancel,
  okText,
  cancelText,
  onBack,
  checkBack,
  isOnlyOkButton,
  isDisabledOk,
}: Pick<
  ModalProps,
  | "onOk"
  | "onCancel"
  | "okText"
  | "cancelText"
  | "onBack"
  | "checkBack"
  | "isOnlyOkButton"
  | "isDisabledOk"
>) => {
  if (isOnlyOkButton) {
    return (
      <S.FooterModal>
        <S.ButtonStyled
          onClick={onOk}
          className={classNames("payment", isDisabledOk && "not-allowed")}
          disabled={isDisabledOk}
        >
          {okText}
        </S.ButtonStyled>
      </S.FooterModal>
    );
  }

  return (
    <S.FooterModal>
      <S.ButtonCancel onClick={() => (checkBack ? onBack?.() : onOk?.())}>
        {cancelText ? cancelText : "View ticket list"}
      </S.ButtonCancel>
      <S.ButtonStyled
        onClick={onCancel}
        className={classNames("payment", isDisabledOk && "not-allowed")}
        disabled={isDisabledOk}
      >
        {okText ? okText : "Join Raffle"}
      </S.ButtonStyled>
    </S.FooterModal>
  );
};

export default PopUp;
