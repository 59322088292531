import styled from "styled-components";

export const ContainerNavigate = styled.div`
  width: 100%;
  display: none;
  @media screen and (max-width: 768px) {
    position: sticky;
    top: 0;
    background-color: transparent;
    z-index: 99;
    display: block;
    padding-bottom: 5px;
  }
`;
export const TabProfile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  .active {
    color: #00e5e8;
    border-bottom: 1px solid #00e5e8;
    p {
      font-weight: 700;
    }
  }
  > div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b3b3b3;
    padding: 12px 0;
    border-bottom: 1px solid #b3b3b3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }
`;
