import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 25px;
  a {
    text-decoration: none !important;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 16px;
  border-bottom: 1px solid #6ee2f54d;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

export const UserContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  button:hover {
    background-color: transparent !important;
  }
  .nv-bellIcon {
    color: white;
    width: 100px !important;
    height: auto !important;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DropdownTop = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4.8px;
`;

export const DropdownBottom = styled.div`
  position: absolute;
  right: 0;
  z-index: 1000;
  width: 166px;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );

  border-radius: 12px;
  padding: 8px;
  margin-top: 4px;
  a {
    text-decoration: none;
  }
`;

export const DropdownOption = styled.div`
  display: flex;
  align-items: end;
  padding: 6px;
  span {
    font-size: 14px;
    margin-left: 6px;
    color: white;
  }
  .signout {
    color: #f47c9a;
  }
`;

export const BackButton = styled.div`
  width: fit-content;
  height: 30px;
  cursor: pointer;

  div {
    border: 1px solid #00b8ba;
    border-radius: 8px;
    padding: 4px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    -webkit-background-clip: text;
    background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
    background-clip: text;
    color: transparent;
  }
`;
export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    padding: 10px 40px;
    color: orange;
  }
`;
