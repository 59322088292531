import Img from "@assets/img";
import Banner from "@assets/img/dashboard/bannerDashboard.png";
import BannerDesktop from "@assets/img/dashboard/bannerDashboarDesktop.svg";
import RobotDashboard from "@assets/img/Robot.svg";
import HeaderLogoLogin from "@assets/img/login/robot_head_login.png";
import WnnrLogo from "@assets/img/WnnrLogo.svg";
import WnnrLogoHeader from "@assets/img/WnnrLogoHeader.svg";
import WnnrBackground from "@assets/img/WnnrBackground.png";
import LoginRobot from "@assets/img/LoginRobot.svg";

const Logo = Img.Logo;
const BackgroundImage = Img.Header;
const Robot = Img.Robot;

export default {
  WnnrLogo,
  Logo,
  BackgroundImage,
  Robot,
  LoginRobot,
  Banner,
  // Resolution: 4:1 (2069px x 463px)
  BannerDesktop,
  HeaderLogoLogin,
  WnnrBackground,
  RobotDashboard,
  WnnrLogoHeader,
};
