import React from "react";
import * as S from "./style";
import Img from "@assets/img";
import moment from "moment";
import { TimeKey, ObjKey, TicketStatus } from "@const/profile";
import { UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface TableProps {
  dataHeader: string[];
  dataRows: any[];
  nameStatus: Record<string, string>;
  currentPage: number;
  limit: number;
}

const TicketTable: React.FC<TableProps> = ({
  dataHeader,
  dataRows,
  nameStatus,
  currentPage,
  limit,
}) => {
  const navigate = useNavigate();
  const formatText = (text: string) => {
    const formattedText = text
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
    return formattedText;
  };
  const indexNumber = (currentPage - 1) * limit;

  const formatId = (index: number) => {
    const stt = indexNumber + index + 1;
    return stt < 10 ? `0${stt}` : stt.toString();
  };

  const statusActions = (index: number, item: any) => ({
    Available: (
      <button
        onClick={() => {
          navigate("/dashboard");
        }}
        className="tooltip-btn"
      >
        <img src={Img.Select} alt="select" id={`selectRaffle_${index}`} />
        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target={`selectRaffle_${index}`}
        >
          Click to select raffle
        </UncontrolledTooltip>
      </button>
    ),
    Used: (
      <button className="tooltip-btn">
        <img src={Img.Eye} alt="eye" id={`viewRaffleResult_${index}`} />
        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target={`viewRaffleResult_${index}`}
        >
          View raffle result
        </UncontrolledTooltip>
      </button>
    ),
    Registered: (
      <button
        className="tooltip-btn"
        onClick={() => {
          navigate(`/raffle/${item.id}`);
        }}
      >
        <img src={Img.Regis} alt="regis" id={`viewRaffle_${index}`} />
        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target={`viewRaffle_${index}`}
        >
          View raffle
        </UncontrolledTooltip>
      </button>
    ),

    default: <td key="action"></td>,
  });

  const renderDataRow = (item, index) => {
    const rowCells = ObjKey.map((key, rowIndex) => {
      let cellValue = item[key];
      if (key === "id") {
        cellValue = formatId(index);
      } else if (TimeKey.includes(key)) {
        if (cellValue !== null) {
          cellValue = moment(cellValue).format("DD/MM/YYYY");
        }
      } else {
        cellValue =
          typeof item[key] !== "string" ? item[key] : formatText(item[key]);
      }

      const cellColor = nameStatus[cellValue] || "";
      return (
        <td key={key} style={{ color: cellColor }}>
          {cellValue}
        </td>
      );
    });

    const rewardStatus = (item) => {
      if (item.status === TicketStatus.USED && item.reward === null) {
        return <div style={{ fontSize: "14px" }}>None</div>;
      } else return item.reward;
    };

    rowCells.push(<td>{rewardStatus(item)}</td>);

    const status = formatText(item.status) || "default";

    rowCells.push(
      <td key="action" style={{ display: "flex" }}>
        {statusActions(index, item)[status]}
      </td>
    );

    return <tr key={index}>{rowCells}</tr>;
  };

  return (
    <S.TableFull>
      <S.Table>
        <thead>
          <tr>
            {dataHeader?.map((headerItem, index) => (
              <th key={index}>{headerItem}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataRows?.length > 0 ? (
            dataRows?.map((item, index) => {
              return renderDataRow(item, index);
            })
          ) : (
            <></>
          )}
        </tbody>
      </S.Table>
    </S.TableFull>
  );
};

export default TicketTable;
