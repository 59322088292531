import { timer } from "@utils/Helper";
import React, { useEffect, useState } from "react";

interface Props {
  time?: Date;
  exTime?: Date;
  isCancel?: boolean;
}

const CountdownTimer = ({ time, exTime, isCancel }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState(timer(time, exTime));

  useEffect(() => {
    if (isCancel) return setTimeRemaining("Cancelled");

    const interval = setInterval(() => {
      setTimeRemaining(timer(time, exTime));
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return <span>{timeRemaining}</span>;
};

export default CountdownTimer;
