import * as React from "react";
import * as S from "./style";

type TextInputProps = React.ComponentPropsWithoutRef<"input"> & {
  label: string;
  wrapperClassName?: string;
};

export const TextInput = ({
  label,
  wrapperClassName,
  ...rest
}: TextInputProps) => {
  return (
    <S.Container className={wrapperClassName}>
      <label>{label}</label>
      <input {...rest} />
    </S.Container>
  );
};
