import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const Loading: React.FC = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "250px",
        }}
      >
        <ThreeCircles
          visible={true}
          height={100}
          width={100}
          color="#5ecbf2"
          ariaLabel="three-circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    </div>
  );
};

export default Loading;
