import moment from "moment";

export const calculateTimeRemaining = (time: Date) => {
  const now = moment();
  const target = moment(time);
  const duration = moment.duration(target.diff(now));
  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};
export const randomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertTime = (num: number) => {
  if (num < 60) {
    return "Less than a minute";
  }
  const days = Math.floor(num / (24 * 60 * 60));
  num %= 24 * 60 * 60;
  const hours = Math.floor(num / (60 * 60));
  num %= 60 * 60;
  const minutes = Math.floor(num / 60);
  const d = `${days} ${days > 1 ? " Days" : " Day"}`;
  const h =
    hours.toString().padStart(2, "0") + `${hours > 1 ? " Hours" : " Hour"}`;
  const m =
    minutes.toString().padStart(2, "0") +
    `${minutes > 1 ? " Minutes" : " Minute"}`;

  if (days === 0) {
    return [h, m].join(" ");
  }
  return [d, h, m].join(" ");
};

export const timer = (time?: Date, exTime?: Date) => {
  if (moment(exTime).unix() < moment().unix()) {
    return "Close";
  }

  const second = moment(time).unix() - moment().unix();

  if (second < 30) {
    return "Draw In Progress";
  }

  return convertTime(second);
};
