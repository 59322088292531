import React, { useEffect, useState } from "react";

import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import HeaderBar from "@components/HeaderBar";
import Navigate from "@components/Navigator";
import ReferFriend from "@components/ReferFriend";
import SideBar from "@components/SideBar";
import TabSide from "@components/TabSide";
import { Outlet, useLocation } from "react-router-dom";
import * as S from "./style";
import { routes } from "@const/routes";
import useDataSideBar from "src/hooks/useDataSideBar";

const HIDE_TABSIDE_PATHS = [
  routes.dashboard,
  routes.raffleResult,
  routes.raffleResultDetail,
];

export const DashboardLayout = () => {
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const { DataSideDashboardBar } = useDataSideBar();
  const isHideTabside = HIDE_TABSIDE_PATHS.some((pathPattern) => {
    const regex = new RegExp(pathPattern.replace(":id", "\\d+"));
    return regex.test(location.pathname);
  });

  useEffect(() => {
    const changeBackGround = () => {
      if (window.scrollY > 20) {
        setNavbar(true);
      } else setNavbar(false);
    };

    window.addEventListener("scroll", changeBackGround);
    return () => {
      window.removeEventListener("scroll", changeBackGround);
    };
  }, [navbar]);

  return (
    <S.Wrapper>
      <SideBar sideData={DataSideDashboardBar} />
      <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
        <Navigate />
        <BreadcrumbNav />
        {!isHideTabside && <TabSide tabData={DataSideDashboardBar} />}
      </S.ContainerNavigate>
      <S.ContainerWrapper>
        <HeaderBar />
        <Outlet />
      </S.ContainerWrapper>
      <ReferFriend />
    </S.Wrapper>
  );
};
