export default class DecryptedData {
  email!: string;
  code!: string;
  phoneNumber!: string;
  ticket!: {
    type: string;
    source: string;
    sourceId: number;
  };
}
