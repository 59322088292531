import React from "react";

// core components
import Navigate from "../Navigate";
import Header from "../Header";
import Footer from "../Footer";

// index sections
import SectionButtons from "./SectionButtons";
import SectionNavbars from "./SectionNavbars";
import SectionNavigation from "./SectionNavigation";
import SectionProgress from "./SectionProgress";
import SectionNotifications from "./SectionNotifications";
import SectionTypography from "./SectionTypography";
import SectionJavaScript from "./SectionJavaScript";
import SectionCarousel from "./SectionCarousel";
import SectionNucleoIcons from "./SectionNucleoIcons";
import SectionDark from "./SectionDark";
import SectionLogin from "./SectionLogin";
import SectionExamples from "./SectionExamples";
import SectionDownload from "./SectionDownload";

const Exam: React.FC = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("exam");
    return function cleanup() {
      document.body.classList.remove("exam");
    };
  });
  return (
    <>
      <Navigate />
      <Header />
      <div className="main">
        <SectionButtons />
        <SectionNavbars />
        <SectionNavigation />
        <SectionProgress />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavaScript />
        <SectionCarousel />
        <SectionNucleoIcons />
        <SectionDark />
        <SectionLogin />
        <SectionExamples />
        <SectionDownload />
        <Footer />
      </div>
    </>
  );
}

export default Exam;

