import UrielSoberanes from "./uriel-soberanes.jpg";
import KaciBaum2 from "./faces/kaci-baum-2.jpg";
import ErikLucatero2 from "./faces/erik-lucatero-2.jpg";
import LoginImage from "./login-bg.svg";
import DanielOlahh from "./daniel-olahh.jpg";
import ClemOnojeghuo3 from "./faces/clem-onojeghuo-3.jpg";
import JoeGardner2 from "./faces/joe-gardner-2.jpg";
import Logo from "./logo.svg";
import Avatar from "./avatar.svg";
import Search from "./search.svg";
import Header from "./header.svg";
import HeaderIcon from "./header-icon.svg";
import DirRight from "./dir-right.svg";
import Fb from "./fb.svg";
import X from "./x.svg";
import Ins from "./ins.svg";
import In from "./in.svg";
import P from "./p.svg";
import Phone from "./phone.svg";
import ChevronRight from "./chevron-right.svg";
import ChevronLeft from "./chevron-left.svg";
import ChevronDown from "./chevron-down.svg";
import Award from "./award.svg";
import Clock from "./clock.svg";
import Heart from "./heart.svg";
import Participant from "./participant.svg";
import Profile from "./profile.svg";
import SignOut from "./sign-out.svg";
import userLogo from "./userLogo.svg";
import walletLogo from "./walletLogo.svg";
import heartLogo from "./heartLogo.svg";
import dashboardLogo from "./dashboardLogo.svg";
import lightningLogo from "./lightningLogo.svg";
import cupLogo from "./cupLogo.svg";
import keyLogo from "./keyLogo.svg";
import bgDesktop from "./bgDesktop.svg";
import bgMobile from "./bgMobile.svg";
import closeLogo from "./closeLogo.svg";
import hamburgerLogo from "./hamburgerLogo.svg";
import Select from "./Select.svg";
import Eye from "./Eye.svg";
import FireLogo from "./FireLogo.svg";
import ReferFriendIcon from "./ReferFriendIcon.svg";
import CalenderIcon from "./CalenderIcon.svg";
import bannerSS from "./bannerSS.svg";
import BackButton from "./back-button.svg";
import NoSelectionBg from "./no-selection-bg.png";
import StarIcon from "./star-icon.svg";
import Regis from "./Regis.svg";
import SelectingIcon from "./selecting.svg";
import UnavailableIcon from "./unavailable.svg";
import AvailableIcon from "./available.svg";
import rocket from "./rocket.svg";
import MachineSlot from "./MachineSlot.svg";
import EmptyHeart from "./empty-heart.svg";
import BullsEye from "./bullseye-icon.svg";
import Gift from "./gift.svg";
import FacebookSolid from "./socials/facebook.svg";
import XSolid from "./socials/x.svg";
import InstagramSolid from "./socials/instagram.svg";
import MessengerSolid from "./socials/messenger.svg";
import WhatsappSolid from "./socials/whatsapp.svg";
import RaffleCancel from "./raffle-cancel.svg";
import CreeperHead from "./creeper-head.svg";
import Loading from "./loading.gif";
import Home from "./home.svg";
import RobotDashboard from "./robotDashboard.svg";
import Share from "./share.svg";
import LadderBoard from "./leaderboard.png";
import Robot from "./robot-img.svg";
import TrophyCup from "./trophy-cup.svg";
import BgCollapsed_1 from "./bg-title_1.svg";
import BgCollapsed_2 from "./bg-title_2.svg";
import BgCollapsed_3 from "./bg-title_3.svg";
import BgCollapsed_4 from "./bg-title_4.svg";
import BgCollapsedBig_1 from "./bg-title-collapsed_1.svg";
import BgCollapsedBig_2 from "./bg-title-collapsed_2.svg";
import BgCollapsedBig_3 from "./bg-title-collapsed_3.svg";
import BgCollapsedBig_4 from "./bg-title-collapsed_4.svg";
import Mascot_1 from "./mascot__1.svg";
import Mascot_2 from "./mascot__2.svg";
import Mascot_5 from "./mascot__5.svg";
import Mascot_7 from "./mascot__7.svg";
import Robot2 from "./robot_2.png";

export default {
  TrophyCup,
  UrielSoberanes,
  KaciBaum2,
  ErikLucatero2,
  LoginImage,
  DanielOlahh,
  ClemOnojeghuo3,
  JoeGardner2,
  Logo,
  Avatar,
  Search,
  Header,
  HeaderIcon,
  DirRight,
  Fb,
  X,
  Ins,
  In,
  P,
  Phone,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  Award,
  Clock,
  Heart,
  Participant,
  Profile,
  Robot,
  SignOut,
  userLogo,
  walletLogo,
  heartLogo,
  dashboardLogo,
  lightningLogo,
  cupLogo,
  keyLogo,
  bgMobile,
  bgDesktop,
  closeLogo,
  hamburgerLogo,
  Select,
  Eye,
  FireLogo,
  ReferFriendIcon,
  CalenderIcon,
  bannerSS,
  BackButton,
  NoSelectionBg,
  StarIcon,
  Regis,
  SelectingIcon,
  UnavailableIcon,
  AvailableIcon,
  rocket,
  MachineSlot,
  EmptyHeart,
  BullsEye,
  Gift,
  FacebookSolid,
  XSolid,
  InstagramSolid,
  MessengerSolid,
  WhatsappSolid,
  RaffleCancel,
  CreeperHead,
  Loading,
  Home,
  RobotDashboard,
  Share,
  LadderBoard,
  BgCollapsed_1,
  BgCollapsed_2,
  BgCollapsed_3,
  BgCollapsed_4,
  BgCollapsedBig_1,
  BgCollapsedBig_2,
  BgCollapsedBig_3,
  BgCollapsedBig_4,
  Robot2,
  Mascot_1,
  Mascot_2,
  Mascot_5,
  Mascot_7,
};
