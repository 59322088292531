import React from "react";
import * as S from "./style";

export interface PaginateProps {
  totalPages: number;
  currentPage: number;
  onPageChange?: ((newPage: number) => void) | undefined;
}

const PaginationAble: React.FC<PaginateProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const generatePageNumbers = (currentPage: number, totalPages: number) => {
    const pageNumbers: any = [1];
    if (totalPages > 1 && currentPage - 1 > 1) {
      if (currentPage - 1 !== 2) pageNumbers.push("...");
      pageNumbers.push(currentPage - 1);
    }

    if (totalPages !== 1 && currentPage !== 1) pageNumbers.push(currentPage);
    if (currentPage + 1 <= totalPages) {
      pageNumbers.push(currentPage + 1);
    }

    if (currentPage + 1 < totalPages) {
      if (currentPage + 1 !== totalPages - 1) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const handleClick = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const renderedPageNumbers = (currentPage: number, totalPages: number) => {
    const pageNumbers = generatePageNumbers(currentPage, totalPages);
    return (
      <div className="pagination">
        {pageNumbers.map((numberOrEllipsis: any, index: number) => (
          <React.Fragment key={index}>
            {typeof numberOrEllipsis === "number" ? (
              <button
                onClick={() => handleClick(numberOrEllipsis)}
                className={numberOrEllipsis === currentPage ? "active" : ""}
              >
                {numberOrEllipsis}
              </button>
            ) : (
              <span>{numberOrEllipsis}</span>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <S.Pagination>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &#60;
      </button>
      {renderedPageNumbers(currentPage, totalPages)}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        &#62;
      </button>
    </S.Pagination>
  );
};

export default PaginationAble;
