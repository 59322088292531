import styled from "styled-components";

export const Wrapper = styled.div`
  width: 240px;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    341.1deg,
    rgba(110, 226, 245, 0.3) -28.66%,
    rgba(100, 84, 240, 0.3) 87.23%
  );
  @media screen and (max-width: 768px) {
    display: none;
  }

  button {
    border: none;
  }
`;
export const Container = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .logo {
    margin: 40px 0 24px 0;
  }

  .title {
    min-height: unset;
  }

  .active {
    padding: 8px 12px;
    border-radius: 12px;
    background: linear-gradient(113.96deg, #64e8de 0%, #8a64eb 100%);
  }
  .non-active {
    padding: 8px 12px;
  }
  img {
    cursor: pointer;
  }
`;
export const SideContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  p {
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  .box-contain {
    width: fit-content;
    background: linear-gradient(
      341.1deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
    padding: 4px;
    border-radius: 6px;
  }
`;
