import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import classnames from "classnames";
import styled from "styled-components";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUp from "@components/PopUp";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";

import Img from "@assets/img";
import { useSelector } from "react-redux";
import { RootState, clearStateAndPersist } from "@store/store";
import { API_END_POINTS } from "@utils/EndPoint";
import { useDispatch } from "react-redux";
import { EAppAction } from "@store/appReducer";
import * as S from "../components/PopUp/style";

export const Ava = styled.span<{ bg: string }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: cover;
  background-image: url(${(p) => p.bg});
`;

export const NavProfile = styled(Nav)`
  align-items: center;
`;

export const Profile = styled.div``;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    padding: 10px 40px;
    color: orange;
  }
`;

const Navigate: React.FC = () => {
  const dispatch = useDispatch();
  const [navbarColor, setNavbarColor] = useState<string>("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isLoginPage, setIsLoginPage] = useState<boolean>(false);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  useEffect(() => {
    if (location.pathname === API_END_POINTS.LOGIN) {
      setIsLoginPage(true);
    }

    const updateNavbarColor = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        setNavbarColor("");
      } else {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return () => {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  const handleEarnGameTickets = () => {
    if (!user) {
      navigate("/login");
      return;
    }

    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: true,
    });
  };
  const logout = () => {
    clearStateAndPersist();
    navigate("/");
  };

  useEffect(() => {
    setIsLogin(!!user);
  }, [user]);

  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
  }, []);

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container style={{ flexWrap: "nowrap" }}>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/"
            title="WNNR.IN"
            tag={Link}
          >
            <img src={DashboardConstant.WnnrLogoHeader} width={160} />
          </NavbarBrand>
          <div style={{ display: "flex" }}>
            <Button
              hidden={!!user || isLoginPage}
              type="button"
              onClick={() => navigate(API_END_POINTS.LOGIN)}
              className="btn-round mr-1 btn btn-primary lg-hide"
            >
              Login <FontAwesomeIcon icon={["fas", "sign-in"]} />
            </Button>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                style={{
                  textDecoration: "none",
                }}
                to="/dashboard"
                className="navbar-item"
                tag={Link}
              >
                Games Lobby
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{
                  textDecoration: "none",
                }}
                href="/how"
                className="navbar-item"
              >
                How it works
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{
                  textDecoration: "none",
                }}
                onClick={handleEarnGameTickets}
                className="navbar-item"
              >
                Earn Game Tickets
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Profile hidden={!isLogin}>
          <UncontrolledDropdown inNavbar>
            <DropdownToggle
              aria-expanded={false}
              aria-haspopup={true}
              color="default"
              data-toggle="dropdown"
              href=""
              nav
              onClick={(e) => e.preventDefault()}
              role="button"
            >
              <Ava bg={Img.Avatar} />
            </DropdownToggle>
            <DropdownMenu className="dropdown-danger dropdown-metadata" right>
              <DropdownItem
                href=""
                onClick={() => navigate(API_END_POINTS.PROFILE)}
              >
                Profile
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="" onClick={() => setVisiblePopup(true)}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Profile>
        <Button
          hidden={!!user || isLoginPage}
          type="button"
          onClick={() => navigate(API_END_POINTS.LOGIN)}
          className="btn-round mr-1 btn btn-primary mb-hide"
        >
          Login <FontAwesomeIcon icon={["fas", "sign-in"]} />
        </Button>
      </Container>
      <PopUp
        visible={visiblePopup}
        title="Gotta go?"
        footer={false}
        onOk={() => {
          setVisiblePopup(false);
          logout();
        }}
        onCancel={() => setVisiblePopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <PopUpContainer>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <img src={Img.Mascot_1} alt="fire" width={180} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="popup-title">Log Out Session?</div>
              <S.FooterModal>
                <S.ButtonStyled
                  onClick={() => {
                    setVisiblePopup(false);
                    logout();
                  }}
                  style={{ marginRight: "15px", marginLeft: "10px" }}
                >
                  Yes
                </S.ButtonStyled>
                <S.ButtonCancel
                  onClick={() => {
                    setVisiblePopup(false);
                  }}
                >
                  No
                </S.ButtonCancel>
              </S.FooterModal>
            </div>
            <div></div>
          </div>
        </PopUpContainer>
      </PopUp>
    </Navbar>
  );
};

export default Navigate;
