export default function buildQuery(
  path: string,
  queries?: Record<string, any>
): string {
  if (!path) return "";

  const params = queries
    ? "?" +
      Object.entries(queries)
        .filter(([key, value]) => key && value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";

  return path + params;
}
