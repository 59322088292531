import styled, { css } from "styled-components";
import { ButtonType, VARIANTS } from "./type";

export const Button = styled.button<ButtonType>`
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 4.5px 17px;
  border-radius: 6px;
  border: 1px solid #00ffed;
  color: #00ffed;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  ${({ variant }) =>
    variant === VARIANTS.PRIMARY &&
    css`
      color: #fff;
      background: linear-gradient(113.96deg, #00ffed 0%, #00b8ba 100%);
      border: none;
    `}

  .icon_wrapper {
    display: inline;
    margin-right: 8px;
  }
`;
