import { endSession } from "@network/User/apiUser";
import { useEffect } from "react";

type TProps = {
  isLoggedIn: boolean;
};

const useSession = ({ isLoggedIn }: TProps) => {
  useEffect(() => {
    function logData() {      
      if (isLoggedIn) endSession()
    }

    document.addEventListener("beforeunload", logData);

    return () => {
      document.removeEventListener("beforeunload", logData);
    };
  }, []);
};

export default useSession;
