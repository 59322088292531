import { io } from 'socket.io-client';
import { store, RootState } from "../store/store";

export const socket = io(process.env.REACT_APP_SOCKET_URL as string, {
	transports: ['websocket'],
	autoConnect: false
});

export const connect = () => {
	socket.connect()
}

export const disconnect = () => {
	socket.disconnect();
}

export const emit = (event: string, payload: object, callback: any) => {
	const stateData = store.getState() as unknown;
  const state: RootState = stateData as RootState;
  const token = state?.auth?.token;

	socket.emit(event, { ...payload, token });
	callback()
}

export const onEvent = (event: string, callback: any) => {
	socket.on(event, callback);
}