import styled from "styled-components";

export const Container = styled.div`
  label {
    line-height: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    padding: 10px 12px;
    margin-bottom: 20px;
    font-size: 14px;
    outline: none;
    border: 1px solid #6ee2f54d;
    background: linear-gradient(
      to top,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
    box-shadow: 0px 2px 0px 0px #0000000b;
    color: #fff;
  }
`;
