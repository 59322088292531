import styled from "styled-components";

export const TableFull = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 70px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  thead {
    background: linear-gradient(
      341.1deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
  }
  tbody {
    tr {
      border-bottom: 1px solid #3258a3;
      /* border-bottom: 1px solid;
        border-image: linear-gradient(
          341.1deg,
          rgba(110, 226, 245, 0.3) -28.66%,
          rgba(100, 84, 240, 0.3) 87.23%
        );
        border-image-slice: 1; */
    }
  }

  th {
    padding: 12px 8px;
    text-align: left;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px 8px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* text-transform: capitalize; */
  }

  td:last-child {
    display: flex;
    overflow: visible; 
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* position: relative; */
    button {
      position: relative;
      background: none;
      border: none;
      padding: 0;
    }
    .tooltip-text {
      visibility: hidden;
      background-color: #333;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 999;
      right: 10%;
      top: 50%;
      opacity: 0;
      transition: opacity 0.3s;
    }

    button:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }
  }



`;


