import React, { useState } from "react";

import Img from "@assets/img";
import CountdownTimer from "@components/CountdownTimer";
import { RAFFLE_STATUS } from "@const/dashboard";
import { updateFavorite } from "@network/Profile";
import { useLocation } from "react-router-dom";
import * as S from "./style";
import { ClockCircleIcon } from "@components/Icons";
import { routes } from "@const/routes";
import moment from "moment";
import { RAFFLE_ITEM_DATE_TIME_FORMAT } from "@const/index";
import ShareRaffle from "./ShareRaffle";
import defaultRaffleThumbnail from "@assets/img/raffle/defaultThumbnailRaffle.png";

interface RaffleProps {
  id: number;
  raffleId: number;
  title: string;
  type: string;
  status: string;
  startTime: Date;
  expiredDate: Date;
  category: string;
  numberOfTurns: number;
  numberOfParticipants: number;
  thumbnailImg: string;
  bannerImg: string;
  rn: string;
  favorite: boolean;
  updateFavoriteList: (id: number) => void;
}

const RaffleItem = ({
  id,
  raffleId,
  title,
  status,
  startTime,
  expiredDate,
  numberOfTurns,
  thumbnailImg,
  favorite,
  updateFavoriteList,
}: RaffleProps) => {
  const location = useLocation();
  const [isFavorite, setIsFavorite] = useState<boolean>(favorite);
  const isRaffleResultPage = location.pathname === routes.raffleResult;
  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);

  const handleFavorite = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    try {
      await updateFavorite(raffleId);
      setIsFavorite(!isFavorite);
      updateFavoriteList(raffleId);
    } catch (err) {
      console.log(err);
    }
  };

  const appUrl = window.location.origin;
  const raffleUrl = `${appUrl}/raffle/${id}`;

  const handleShareRaffle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setOpenSharePopup(true);
  };
  const redirectTo = (() => {
    let redirectPage = routes.raffle;
    if (isRaffleResultPage) redirectPage = routes.raffleResult;

    return `${redirectPage}/${id}`;
  })();

  return (
    <>
      <S.Container to={redirectTo} key={id}>
        <S.Wrapper>
          <S.Image src={thumbnailImg || defaultRaffleThumbnail}></S.Image>

          <S.Bottom>
            <h1>{title}</h1>
            {!isRaffleResultPage && (
              <>
                <S.Info>
                  <img src={Img.Clock} />
                  <div>
                    Raffles Starts In:{" "}
                    {<CountdownTimer time={startTime} exTime={expiredDate} />}
                  </div>
                </S.Info>
                <S.Info>
                  <img src={Img.Award} />
                  <div>Prizes: {numberOfTurns}</div>
                </S.Info>
              </>
            )}
            {isRaffleResultPage ? (
              <S.Info>
                <img src={Img.Clock} style={{ marginRight: 2 }} />
                <div>
                  Next Game In:{" "}
                  {<CountdownTimer time={startTime} exTime={expiredDate} />}{" "}
                  Time:{" "}
                  {moment(startTime.toString()).format(
                    RAFFLE_ITEM_DATE_TIME_FORMAT
                  )}
                </div>
              </S.Info>
            ) : (
              <S.Footer>
                <S.Title
                  className={status === "WAITING_PEOPLE" ? "waiting" : ""}
                >
                  {RAFFLE_STATUS[status]}
                </S.Title>
                <S.Info>
                  <button onClick={handleShareRaffle}>
                    <img src={Img.Share} />
                  </button>
                  <button onClick={(e) => handleFavorite(e)}>
                    {isFavorite ? (
                      <img src={Img.Heart} />
                    ) : (
                      <img src={Img.EmptyHeart} />
                    )}
                  </button>
                </S.Info>
              </S.Footer>
            )}
          </S.Bottom>
        </S.Wrapper>
      </S.Container>
      <ShareRaffle
        isOpen={openSharePopup}
        url={raffleUrl}
        setIsOpen={() => setOpenSharePopup(false)}
      />
    </>
  );
};

export default RaffleItem;
