import React from "react";
import * as S from "./style";

type BaseRadioProps = React.ComponentPropsWithoutRef<"input"> & {
  label: React.ReactNode | string;
  className?: string;
};

const Checkbox = ({ label, checked, className, ...rest }: BaseRadioProps) => (
  <S.CheckboxContainer className={className}>
    <S.HiddenCheckbox checked={checked} {...rest} />
    <S.StyledCheckbox />
    {typeof label === "string" ? (
      <S.CheckboxLabel>{label}</S.CheckboxLabel>
    ) : (
      label
    )}
  </S.CheckboxContainer>
);

export default Checkbox;
