import styled from "styled-components";

export const Wrapper = styled.div`
  .breadcrumb {
    background: transparent;

    border-radius: 0%;
    padding: 10px 0px 10px 5px;
    margin: 0;
  }

  .breadcrumb-item {
    color: #b3b3b3 !important;
    padding: 0;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .breadcrumb-item.active {
    color: #fff !important;
  }

  .breadcrumb-item:hover {
    color: #fff;
  }
  .wrapper {
    width: 100%;
    background: linear-gradient(
      341.1deg,
      rgba(110, 226, 245, 0.3) -28.66%,
      rgba(100, 84, 240, 0.3) 87.23%
    );
  .container {
    max-width: 1024px;
    margin: auto;
  }
   @media (max-width: 767px) {
    .container-sm, .container {
    }
}
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

`;
