import { SCREEN } from "@type/index";

export interface ParamType {
  limit?: number;
  offset?: number;
  type?: string;
  id?: number;
  screen?: SCREEN;
  filter?: string;
  searchBy?: SearchBy;
  keyword?: string;
}

export interface RaffleParams {
  keyword?: string;
  searchBy?: string;
  filter?: string;
  limit: number;
  offset: number;
  type: string;
  screen: SCREEN;
}

export enum SearchBy {
  TITLE = "title",
}

export interface RaffleResultResData {
  data: {
    title: string;
    startTime: string;
    winners: WinnersResData[];
  };
}

export interface WinnersResData {
  username: string;
  phoneNumber: string;
  luckyNumber: number;
  award: string;
  turn: number;
}
