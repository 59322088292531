import ReferFriendPopup from "@components/ReferFriendPopup";
import { RootState } from "@store/store";
import React from "react";
import { useSelector } from "react-redux";

const ReferFriend = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const isOpenReferPopup = useSelector((state: RootState) =>
    Boolean(state.app.isShowReferPopup)
  );
  return <>{isLoggedIn && <ReferFriendPopup isOpen={isOpenReferPopup} />}</>;
};

export default ReferFriend;
