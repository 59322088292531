import React from "react";
import { Svg } from "./svg";

export const RadioIcon = ({ checked }: { checked?: boolean }) => {
  if (checked) {
    return (
      <Svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.45929"
          y="2.45941"
          width="14.7557"
          height="14.7557"
          rx="7.37785"
          stroke="url(#paint0_linear_3330_46951)"
          strokeWidth="1.63952"
        />
        <rect
          x="5.7384"
          y="5.73828"
          width="8.19761"
          height="8.19761"
          rx="4.0988"
          fill="url(#paint1_linear_3330_46951)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3330_46951"
            x1="1.63953"
            y1="1.63965"
            x2="21.4152"
            y2="10.4288"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFED" />
            <stop offset="1" stop-color="#00B8BA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3330_46951"
            x1="5.7384"
            y1="5.73828"
            x2="15.6262"
            y2="10.1329"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00FFED" />
            <stop offset="1" stopColor="#00B8BA" />
          </linearGradient>
        </defs>
      </Svg>
    );
  }

  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.62213"
        y="2.62201"
        width="14.7557"
        height="14.7557"
        rx="7.37785"
        stroke="#B3B3B3"
        strokeWidth="1.63952"
      />
    </Svg>
  );
};

export const ClockCircleIcon = ({ className }: { className?: string }) => {
  return (
    <Svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_4047_51495)">
        <path
          d="M6 0.316895C2.68661 0.316895 0 3.0035 0 6.31689C0 9.63029 2.68661 12.3169 6 12.3169C9.31339 12.3169 12 9.63029 12 6.31689C12 3.0035 9.31339 0.316895 6 0.316895ZM6 11.299C3.24911 11.299 1.01786 9.06779 1.01786 6.31689C1.01786 3.566 3.24911 1.33475 6 1.33475C8.75089 1.33475 10.9821 3.566 10.9821 6.31689C10.9821 9.06779 8.75089 11.299 6 11.299Z"
          fill="#B3B3B3"
        />
        <path
          d="M8.33984 8.01117L6.43002 6.63037V3.31564C6.43002 3.25671 6.3818 3.2085 6.32287 3.2085H5.67868C5.61975 3.2085 5.57153 3.25671 5.57153 3.31564V7.00403C5.57153 7.03885 5.5876 7.071 5.61573 7.09109L7.83091 8.70626C7.87912 8.74109 7.94609 8.73037 7.98091 8.6835L8.36394 8.16118C8.39877 8.11162 8.38805 8.04466 8.33984 8.01117Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_4047_51495">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.316895)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};
