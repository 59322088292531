import instance from "@network/api";
import { RequestLogin } from "./type";
import { VERIFY_LINK_TYPE } from "@type/index";

export async function login(payload: Omit<RequestLogin, "invitedBy">) {
  const res = await instance.post("/auth/login-by-phone", payload);

  return res.data;
}

export async function verify(payload: RequestLogin) {
  const res = await instance.post("/auth/verify-login-otp", payload);

  return res.data;
}

export async function verifyLink({
  token,
  type,
}: {
  token: string;
  type: VERIFY_LINK_TYPE;
}) {
  const res = await instance.post("/auth/verify-link", { token, type });
  return res.data;
}

export async function reverifiedEmail(payload: {
  email: string;
  name: string;
  userId: number;
}) {
  const res = await instance.post("/auth/send-verification-link", payload);
  return res.data;
}
