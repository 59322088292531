import { createGlobalStyle } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const GlobalStyle = createGlobalStyle`
  body {
    color: #fff;
    flex-grow: 0; 
    margin: 0px;
    height: 100%;
    background-image: linear-gradient(to bottom, #191852 0%, #1F87EF 100%, #2086EE 100%);
    font-family: "Be Vietnam Pro", "Montserrat", "Helvetica", Arial, sans-serif;
    img {
      height: auto;
      max-width: 100%;
    } 
  }

`;

export default GlobalStyle;
