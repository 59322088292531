export interface Tickets {
  title?: string;
  type?: string;
  source?: string;
  expiredDate?: string;
  limit?: number;
  offset?: number;
  screen?: string;
}

export interface PaginateTicket {
  limit: number;
  offset: number;
  screen?: string;
}

export enum ActionSubscribe {
  SUBSCRIBE = "SUBSCRIBE",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}
