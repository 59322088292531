import styled from "styled-components";

export const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 0;
  padding: 0;
  text-transform: initial;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px #d9d9d9 solid;
  border-radius: 2px;

  ${HiddenCheckbox}:checked + & {
    border: 0;
    background: linear-gradient(180deg, #00ffed 0%, #00b8ba 100%);
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  ${HiddenCheckbox}:checked + &:after {
    display: block;
  }
`;

export const CheckboxLabel = styled.span`
  margin-left: 24px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
