import styled from "styled-components";

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding: 1px 8px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    padding: 1px 8px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #00ffed;
    border: 1px solid #00ffed;
  }

  button {
    border: none;
    background: transparent;
    color: #f2f2f2;
    padding: 6px;
    &:disabled {
      color: gray;
    }
  }
  .hidden {
    display: none;
  }
`;
