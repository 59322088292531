import { verifyLink } from "@network/Auth/apiAuth";
import { RootState } from "@store/store";
import { VERIFY_LINK_TYPE } from "@type/index";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
`;
const VerifiedEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const verify = async () => {
        try {
          const res = await verifyLink({
            token,
            type: VERIFY_LINK_TYPE.VERIFY_EMAIL,
          });
          if (res.status === "success") {
            toast.success("Verified link is successful!");
            setTimeout(() => {
              if (isLoggedIn) {
                navigate("/dashboard");
              } else {
                navigate("/login");
              }
            }, 3000);
          }
          if (res.status === "error") {
            toast.error("Verified link is not successful!");
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        } catch (err: any) {
          toast.error("Verified link is not successful!");
        }
      };
      verify();
    }
  }, [token]);

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default VerifiedEmail;
