import styled from "styled-components";
import Img from "@assets/img";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  .title {
    margin: 40px 0 35px 0;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    @media (max-width: 768px) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
  }
`;
export const SpinContainer = styled.div<{ $bgColor?: string }>`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  width: 58%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: ${(props) =>
    props.$bgColor ||
    "linear-gradient(341deg, rgba(110, 226, 245, 0.6) -28.66%, rgba(100, 84, 240, 0.6) 87.23%)"};
  padding: 25px 45px;

  @media screen and (max-width: 1024px) {
    padding: 20px 5px;
    @media screen and (max-width: 768px) {
      height: fit-content;
      padding: 20px 0;
      width: 100%;
    }
  }
`;

export const MoveText = styled.div<{ $textColor?: string }>`
  width: 100%;
  white-space: nowrap;
  animation: scrollText 10s infinite linear;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 20px;
  span {
    background: ${(props) =>
      props.$textColor || "linear-gradient(114deg, #ffcda5 0%, #ee4d5f 100%)"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
  }
`;

export const SpinContent = styled.div<{
  $titleColor?: string;
  $anotherColor?: string;
}>`
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
  }
  .header-title {
    color: ${(props) => props.$titleColor ?? "#00ffed"};
    text-shadow: ${(props) =>
      `0px 0px 40px ${props.$titleColor}` ?? "0px 0px 40px #00b2ff"};
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 108.333% */
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 35px;
    }
  }
  .turn-playing {
    width: auto;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #4db20e;
    margin: 20px auto;
  }
  .number-playing {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background: ${(props) =>
      props.$anotherColor ??
      "linear-gradient(114deg, #ffcf1b 0%, #ff881b 100%)"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: auto;
  }
  .number-participant {
    display: flex;
    height: 28px;
    padding: 5px 20px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    color: ${(props) => props.$anotherColor ?? "white"};
    background: linear-gradient(
      341deg,
      rgba(110, 226, 245, 0.6) -28.66%,
      rgba(100, 84, 240, 0.6) 87.23%
    );
    backdrop-filter: blur(8.5px);
    margin: auto;
  }
  .number-turn {
    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
  .next-reward {
    margin: auto;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .happen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .happen-in {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      background: ${(props) =>
        props.$anotherColor ??
        "linear-gradient(180deg, #fbec9d 0%, #fdce8c 33.33%, #fbec9d 66.67%,#ffb37c 100%)"};
      margin-bottom: 15px;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .happen-time span {
      color: ${(props) => props.$anotherColor ?? "#fff"};
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }
  }
`;

export const MachineWrapper = styled.div`
  width: 100%;
  height: 190px;
  display: flex;
  @media screen and (max-width: 768px) {
    height: 110px;
    width: 100%;
    @media screen and (max-width: 425px) {
      height: 95px;
    }
  }
`;

export const LuckyNumber = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${Img.MachineSlot});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-sizing: border-box;

  .slot-counter {
    flex: 1 1 auto;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 425px) {
      transform: scale(0.8);
    }
  }
  .char {
    padding: 0 4px;

    @media screen and (max-width: 768px) {
      padding: 0 2px;
    }
    position: relative;
    &:last-child::after {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 14px;
      margin: auto;
      width: 2px;
      height: 70%;
      background: #fbec9d;
      @media screen and (max-width: 768px) {
        height: 100%;
        top: 0;
      }
    }
    span {
      margin-left: 3px;
      font-size: 72px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 8px;
      background: linear-gradient(
        180deg,
        #fbec9d 0%,
        #fdce8c 33.33%,
        #fbec9d 66.67%,
        #ffb37c 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (max-width: 1680px) {
        font-size: 4vw;
      }

      @media screen and (max-width: 1480px) {
        font-size: 4vw;
      }

      @media screen and (max-width: 1380px) {
        font-size: 3.5vw;
      }

      @media screen and (max-width: 1280px) {
        font-size: 2.6vw;
      }

      @media screen and (max-width: 1150px) {
        font-size: 2vw;
      }

      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
  }
`;

export const DetailContainer = styled.div<{
  $bgColor?: string;
  $anotherColor?: string;
}>`
  flex-grow: 1;
  flex-shrink: 1;
  width: 39%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px 20px;
  white-space: pre-line;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  background: ${(props) =>
    props.$bgColor ||
    "linear-gradient(341deg, rgba(110, 226, 245, 0.6) -28.66%, rgba(100, 84, 240, 0.6) 87.23%)"};

  @media screen and (max-width: 768px) {
    width: 100%;
    height: fit-content !important;
  }
  .number {
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 5px;
    background: linear-gradient(
      180deg,
      #fbec9d 0%,
      #fdce8c 33.33%,
      #fbec9d 66.67%,
      #ffb37c 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .header {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 116.667% */
    text-transform: uppercase;
    margin-bottom: 40px;
    color: ${(props) => props.$anotherColor ?? "#fff"};
  }
  .content {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${(props) => props.$anotherColor ?? "#fff"};
  }
`;

export const Hr = styled.hr`
  width: 100%;
  border: 1px solid #6ee2f54d;
`;

export const ImgBox = styled.div<{ bg?: string }>`
  margin-top: 25px;
  border-radius: 12px;
  aspect-ratio: 16 / 9;
  width: 100%;
  background-image: url(${(p) => p.bg});
  background-size: cover;
  background-position: center;
`;

export const Video = styled.video`
  margin-top: 25px;
  border-radius: 12px;
  width: 100%;
  height: 215px;
`;

export const IFrame = styled.iframe`
  margin-top: 25px;
  border-radius: 12px;
  width: 100%;
  height: 215px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
  width: fit-content;
  background-color: ${(props) => (props.disabled ? "grey" : "rgb(234 88 12)")};
  color: white;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 30px;
  margin: 0 auto;
  padding: 12px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;
