import React, { useState } from "react";
import {
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

function SectionProgress() {
  const [activeTab, setActiveTab] = useState<string>("1");
  
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className="section">
        <Container>
          <Row>
            <Col md="6">
              <div className="title">
                <h3>Progress Bar</h3>
                <br />
              </div>
              <Progress max={100} value={25} barClassName="progress-bar-success" />
              <br />
              <Progress max={100} value={50} barClassName="progress-bar-info" />
              <br />
              <Progress max={100} value={100} barClassName="progress-bar-danger" />
              <br />
              <Progress multi>
                <Progress bar max={100} value={15} />
                <Progress bar barClassName="progress-bar-success" max={100} value={30} />
                <Progress bar barClassName="progress-bar-warning" max={100} value={20} />
              </Progress>
            </Col>
            <Col md="6">
              <div className="title">
                <h3>Pagination</h3>
                <br />
              </div>
              <nav aria-label="Page navigation example">
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      aria-label="Previous"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i aria-hidden={true} className="fa fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {/* Pagination items here */}
                </Pagination>
              </nav>
              <br />
              <nav aria-label="...">
                <Pagination>
                  {/* Pagination items here */}
                </Pagination>
              </nav>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="6">
              <div className="title">
                <h3>Navigation Tabs</h3>
              </div>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Home
                      </NavLink>
                    </NavItem>
                    {/* Nav items here */}
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab} className="text-center">
                <TabPane tabId="1">
                  <p>Larger, yet dramatically thinner...</p>
                </TabPane>
                {/* Tab panes here */}
              </TabContent>
            </Col>
            <Col md="6">
              <div className="title">
                <h3>Labels</h3>
              </div>
              {/* Labels here */}
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionProgress;
