import { getListUserRanking } from "@network/LeaderBoard";
import { EAppAction } from "@store/appReducer";
import { RootState } from "@store/store";
import * as S from "../../style";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RightArrow from "@assets/img/right-arrow.svg";
import GoldMedal from "@assets/img/gold-medal.svg";
import SilverMedal from "@assets/img/silver-medal.svg";
import BronzeMedal from "@assets/img/bronze-medal.svg";
import Medal from "@assets/img/other-medal.svg";
import IconLink from "@assets/img/link-icon.png";

const params = {
  limit: 5,
  offset: 0,
};
const fixedColor = [
  "#ee3838",
  "#ffa900",
  "#ff5757",
  "#8c52ff",
  "#04d3bc",
  "#00bf63",
  "#b4a5f1",
  "#8c52ff",
  "#956b0d",
  "#ff66c4",
  "#ee3838",
  "#ffa900",
  "#ff5757",
  "#8c52ff",
  "#04d3bc",
  "#00bf63",
  "#b4a5f1",
  "#8c52ff",
  "#956b0d",
  "#ff66c4",
];

const medals = [GoldMedal, SilverMedal, BronzeMedal, Medal];
export default function LeaderBoard() {
  const [totalItem, setTotalItem] = useState(10);
  const [showViewAll, setShowViewAll] = useState(true);
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userRanking, setUserRanking] = useState<any>([]);
  const getUserRanking = async () => {
    const res = await getListUserRanking(params);
    setUserRanking(res?.data?.refsRanking);
  };
  const onOpenReferPopup = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    dispatch({
      type: EAppAction.SHOW_REFER_POPUP,
      payload: true,
    });
  };
  const totalUser: any = useMemo(() => {
    if (!userRanking) {
      const nullItems = totalItem;
      const totalUserRanking = [...Array(nullItems).fill(null)];
      return totalUserRanking;
    }
    const nullItems = totalItem - userRanking?.length;
    const totalUserRanking = [...userRanking, ...Array(nullItems).fill(null)];
    return totalUserRanking;
  }, [userRanking, totalItem]);

  useEffect(() => {
    getUserRanking();
  }, []);
  return (
    <>
      <S.ContentTitle>Most Referral Points last 30 days</S.ContentTitle>
      <S.LeaderBoard>
        {totalUser?.map((item: any, index: any) => {
          const color = Math.random().toString(16).substr(-6);
          let medal;
          if (index < 3) {
            medal = medals[index];
          } else {
            medal = medals[3];
          }
          const firstLetter = item?.username?.charAt(0)?.toUpperCase();
          return (
            <S.RankOfUser bgColor={fixedColor[index]} key={index}>
              <S.InfoRank>
                <S.RankNumber rankNumber={index + 1} color={`#${color}`}>
                  <div
                    style={{
                      marginTop: 2,
                      color: `white`,
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {firstLetter}
                  </div>
                </S.RankNumber>
                <S.Medal src={medal} width={8} height={8} />
                <S.UserName>{item?.username}</S.UserName>
              </S.InfoRank>
              <S.UserPoint>
                {item?.totalRef ? `${item?.totalRef}R` : null}
              </S.UserPoint>
            </S.RankOfUser>
          );
        })}
      </S.LeaderBoard>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 15,
          fontSize: 12,
        }}
      >
        {showViewAll && (
          <S.ViewAllButton
            onClick={() => {
              setTotalItem(20);
              setShowViewAll(false);
            }}
          >
            VIEW ALL
            <img
              style={{ marginLeft: 6 }}
              width={16}
              height={16}
              src={RightArrow}
            />
          </S.ViewAllButton>
        )}
        {!showViewAll && (
          <S.ViewAllButton
            onClick={() => {
              setTotalItem(10);
              setShowViewAll(true);
            }}
          >
            Hide View All
          </S.ViewAllButton>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          fontSize: "12px",
        }}
      >
        <S.ReferButton onClick={onOpenReferPopup}>
          REFER A FRIEND
          <div
            style={{
              background: "white",
              borderRadius: "50%",
              padding: 2,
              marginLeft: 4,
            }}
          >
            <img
              style={{
                transform: "rotateY(145deg)",
              }}
              src={IconLink}
              width={16}
              height={16}
            />
          </div>
        </S.ReferButton>
      </div>
    </>
  );
}
