import React from "react";
import * as _ from "lodash";

import * as S from "./style";

interface LuckyNumber {
  number: number;
  selected: boolean;
}

interface Props {
  numberList: LuckyNumber[];
  selectedNumber: number[];
  updateSelection: (number: number) => void;
}

const NumberList = ({ numberList, selectedNumber, updateSelection }: Props) => {
  return (
    <S.NumberList>
      {numberList.length > 0 &&
        numberList.map((number) => (
          <S.Number
            key={number.number}
            className={
              number.selected === true
                ? "unavailable"
                : selectedNumber.includes(number.number)
                ? "selected"
                : "available"
            }
            disabled={number.selected == true}
            onClick={() => updateSelection(number.number)}
          >
            {_.padStart(number.number, 3, '0')}
          </S.Number>
        ))}
    </S.NumberList>
  );
};

export default NumberList;
