import { AxiosInstance } from "axios";
import instance from "./api";
import { store, RootState } from "../store/store";

const instanceAuth = (): AxiosInstance => {
  const stateData = store.getState() as unknown;
  const state: RootState = stateData as RootState;
  const token = state?.auth?.token;

  instance.defaults.headers.Authorization = `Bearer ${token}`;

  return instance;
};

export default instanceAuth;
