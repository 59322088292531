const appearanceInbox = {
  baseTheme: {
    variables: {
      colorBackground: "#1F2937",
      colorForeground: "#F9FAFB",
      colorPrimary: "#3B82F6",
      colorSecondaryForeground: "#9CA3AF",
      colorNeutral: "#374151",
    },
  },
  elements: {
    bellIcon: "text-white",
    notificationItem:
      "custom-class bg-gray-800 hover:bg-gray-700 p-4 rounded-md",
    notificationSubject: {
      color: "#E2E8F0",
      fontSize: "18px",
      fontWeight: "bold",
    },
    notificationBody: {
      color: "#A0AEC0",
      fontSize: "14px",
    },
  },
};

export default { appearanceInbox };
