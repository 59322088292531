// import React from "react";

// import * as S from "./style";
// import ExitIcon from "@assets/icons/widget/ExitIcon";
// import FacebookIcon from "@assets/icons/widget/FacebookIcon";
// import TwitterIcon from "@assets/icons/widget/TwitterIcon";
// import InstagramIcon from "@assets/icons/widget/InstagramIcon";
// import LinkedInIcon from "@assets/icons/widget/LinkedInIcon";
// import ThreadsIcon from "@assets/icons/widget/ThreadsIcon";
// import WhatsappIcon from "@assets/icons/widget/WhatsappIcon";

const Widget: React.FC = () => {
  //     const mockupdata = [
  //         {
  //             id: 1,
  //             icon: <FacebookIcon />,
  //             name: "Facebook"
  //         },
  //         {
  //             id: 2,
  //             icon: <TwitterIcon />,
  //             name: "Twitter"
  //         },
  //         {
  //             id: 3,
  //             icon: <InstagramIcon />,
  //             name: "Instagram"
  //         },
  //         {
  //             id: 4,
  //             icon: <LinkedInIcon />,
  //             name: "LinkedIn"
  //         },
  //         {
  //             id: 5,
  //             icon: <ThreadsIcon />,
  //             name: "Threads"
  //         },
  //         {
  //             id: 6,
  //             icon: <WhatsappIcon />,
  //             name: "WhatsApp"
  //         },
  //     ]
  return (
    <></>
    // <S.Wrapper>
    //         <S.Header>
    //             <span>Refer a friend</span>
    //             <button style={{ background: "none", border: "none", padding: 0 }}>
    //                 <ExitIcon />
    //             </button>
    //         </S.Header>
    //         <S.Body>
    //             <S.WidgetList>
    //                 {mockupdata.map((data) => (
    //                     <S.WidgetItem key={data.id}>
    //                         <button style={{ background: "none", border: "none", padding: 0 }}>
    //                             {data.icon}
    //                         </button>
    //                         <div>
    //                             <span>{data.name}</span>
    //                         </div>
    //                     </S.WidgetItem>
    //                 ))}
    //             </S.WidgetList>
    //             <S.WidgetLink>
    //                 <span>https://wnnr.com/tgc1uO087gl?si=mOWPpr</span>
    //                 <div>
    //                     <button>Copy</button>
    //                 </div>
    //             </S.WidgetLink>
    //         </S.Body>
    // </S.Wrapper>
  );
};

export default Widget;
