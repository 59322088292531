import { verifyLink } from "@network/Auth/apiAuth";
import { GIFT_STATUS } from "@network/Questions/type";
import { VERIFY_LINK_TYPE } from "@type/index";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
`;
const Gift: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("code");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const verify = async () => {
        try {
          const res = await verifyLink({
            token,
            type: VERIFY_LINK_TYPE.VERIFY_LOGIN_FROM_GIFT_LINK,
          });
          if (res.status === "success") {
            localStorage.setItem("giftCode", token);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
          if (res.status === "error") {
            if (res.message === GIFT_STATUS.GIFT_LINK_USED) {
              toast.error("Gift link is already used!");
              setTimeout(() => {
                navigate("/");
              }, 1000);
            }
            if (res.message === GIFT_STATUS.GIFT_LINK_EXPIRED) {
              toast.error("Gift link is expired!");
              setTimeout(() => {
                navigate("/");
              }, 1000);
            }
          }
        } catch (err: any) {
          toast.error("Verified link is not successful!");
        }
      };
      verify();
    }
  }, [token]);

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default Gift;
