import Img from "@assets/img";
import PaginationAble from "@components/PaginateAble";
import React from "react";
import * as S from "./style";

interface TableProps {
  dataHeader: string[];
  dataRows: any[];
  currentPage?: number;
  Tbody?: React.JSX.Element;
  hidePagination?: boolean;
}

const Table: React.FC<TableProps> = ({
  dataHeader,
  dataRows,
  Tbody,
  currentPage,
  hidePagination,
}) => {
  const renderDataRow = (item: any, index: number) => {
    const rowCells = Object.keys(item).map((key) => {
      return <td key={key}>{item[key]}</td>;
    });
    return <tr key={index}>{rowCells}</tr>;
  };

  return (
    <>
      <S.TableFull>
        <S.Table>
          <thead>
            <tr>
              {dataHeader?.map((headerItem, index) => (
                <th key={index}>{headerItem}</th>
              ))}
            </tr>
          </thead>
          {dataRows ? (
            <tbody>
              {dataRows?.map((item, index) => {
                return renderDataRow(item, index);
              })}
            </tbody>
          ) : (
            Tbody
          )}
        </S.Table>
      </S.TableFull>
      {dataRows?.length > 0 && !hidePagination && currentPage && (
        <PaginationAble currentPage={currentPage} totalPages={1} />
      )}
      {dataRows?.length <= 0 && (
        <S.NoSelectionBg>
          <img src={Img.NoSelectionBg} alt="No data" width={350} />
          <p>No ticket displayed</p>
        </S.NoSelectionBg>
      )}
    </>
  );
};

export default Table;
