import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Navigate from "@components/Navigate";
import { login, verify } from "@network/Auth/apiAuth";
import { useDispatch } from "react-redux";
import { updateUser } from "@store/authReducer";
import { updateNotice } from "@store/noticeReducer";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
import { Errors } from "@const/error";
import { RequestLogin } from "@network/Auth/type";
import { routes } from "@const/routes";
import styled from "styled-components";
import PopUp from "@components/PopUp";
import { PopupWrapper } from "@components/Footer";
import { EncryptService } from "@utils/encrypt.service";
import DecryptedData from "@utils/decrypdata.service";

export const Title = styled.h3`
  font-size: 48px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 32px;
  }
`;

export const PolicyDescription = styled.div`
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const Link = styled.a`
  font-size: 15px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const privateKey = process.env.REACT_APP_PRIVATE_KEY as string;

const Login: React.FC = () => {
  const { id } = useParams();
  const [phone, setPhone] = useState<string | undefined>();
  const [otp, setOtp] = useState<string>();
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [showTermAndCondition, setShowTermAndCondition] =
    useState<boolean>(false);
  const [payloadFromGift, setPayloadFromGift] = useState<any>();
  const [isInValidPhone, setIsInValidPhone] = useState<boolean>(false);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer - 1 === 0) {
        setIsResend(true);
        clearInterval(interval);
      }
      setTimer((prevTimer) => (prevTimer - 1 >= 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    const giftCode = localStorage.getItem("giftCode");
    if (giftCode) {
      const encrypt = new EncryptService();
      const payload: any = encrypt.decryptUseAES(
        giftCode,
        privateKey,
        DecryptedData
      );
      const parsedPayload = JSON?.parse(payload);
      setPayloadFromGift(parsedPayload);
    }
  }, []);

  const onSend = async () => {
    try {
      if (isError || !phone) return;
      const res = await login({
        phone,
        ...(payloadFromGift ? { source: payloadFromGift.source } : {}),
      });

      if (res.status === "error") {
        return dispatch(
          updateNotice(
            {
              type: "ERROR",
              content: Errors[res.message]
                ? Errors[res.message]
                : Errors.SEND_OTP_ERROR,
            },
            dispatch
          )
        );
      }

      setShowOtp(true);

      setTimer(59);
    } catch (error) {
      console.log(error);
    }
  };

  const onVerify = async () => {
    try {
      if (!otp || !isCheck || !phone) {
        setIsError(true);

        return;
      }

      setIsError(false);
      const verifyPayload: RequestLogin = {
        phone,
        otp,
        ...(payloadFromGift ? { source: payloadFromGift.source } : {}),
      };
      if (id) verifyPayload.invitedBy = Number(id);

      const res = await verify(verifyPayload);

      if (res.status === "error") {
        localStorage.removeItem("giftCode");
        return dispatch(
          updateNotice(
            {
              type: "ERROR",
              content: Errors[res.message]
                ? Errors[res.message]
                : Errors.VERIFY_OTP_ERROR,
            },
            dispatch
          )
        );
      }
      localStorage.removeItem("giftCode");
      dispatch(updateUser(res.data));
      navigate(routes.dashboard);
    } catch (error) {
      console.log(error);
    }
  };

  const showTime = (timer: number) =>
    timer > 9 ? `00:${timer}` : `00:0${timer}`;

  useEffect(() => {
    if (!phone || !/^\d{1,10}$/.test(phone)) {
      setIsError(true);
      if (phone === undefined && !isCheck) {
        setIsInValidPhone(false);
      } else {
        setIsInValidPhone(true);
      }

      return;
    }
    setIsInValidPhone(false);

    if (!isCheck) {
      return setIsError(true);
    }
    setIsError(false);
  }, [isCheck, phone]);

  return (
    <>
      <Navigate />
      <div
        className="page-header page-login"
        style={{
          backgroundImage: `url(${DashboardConstant.WnnrBackground})`,
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5">
              <Card className="card-register ml-auto mr-auto">
                <div className="d-flex align-items-center">
                  <img
                    src={DashboardConstant.LoginRobot}
                    width={100}
                    height={60}
                    style={{ marginTop: "10px" }}
                  />
                  <Title>Login</Title>
                </div>
                <p className="sub-title">
                  Register and login with your mobile OTP
                </p>
                <Form className="register-form">
                  <div
                    className={
                      isError && isCheck && !showOtp
                        ? "has-danger form-group"
                        : "form-group"
                    }
                    style={{ marginBottom: "0px" }}
                  >
                    <label>Phone number</label>
                    <Input
                      disabled={showOtp}
                      placeholder="Input your phone number"
                      value={phone}
                      maxLength={15}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                    />
                    <div
                      className="form-control-feedback"
                      hidden={!isInValidPhone}
                    >
                      Invalid phone number format
                    </div>
                  </div>
                  <Button
                    block
                    className="btn-round"
                    onClick={() => onSend()}
                    disabled={!isCheck || (showOtp && timer !== 0) || !phone}
                    color="success"
                  >
                    {showOtp && timer
                      ? showTime(timer)
                      : isResend
                      ? "Resend"
                      : "Send OTP"}
                  </Button>
                </Form>
                <div className="box-otp" hidden={!showOtp}>
                  <label>Enter OTP to login</label>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    containerStyle="otp-input"
                    numInputs={4}
                    inputType="number"
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className="form-control-feedback" hidden={!isError}>
                    Incorrect OTP
                  </div>
                </div>
                <FormGroup check>
                  <Label check>
                    <Input
                      defaultValue=""
                      type="checkbox"
                      onChange={() => setIsCheck(!isCheck)}
                      checked={isCheck}
                    />
                    <span className="form-check-sign" />
                  </Label>
                  <PolicyDescription>
                    By clicking this you agree to all terms and conditions and
                    give{" "}
                    <Link href="wnnr.in" target="_blank">
                      {" "}
                      wnnr.in
                    </Link>{" "}
                    permission to send you marketing messages from time to time.
                    For all Terms and Conditions,{" "}
                    <span
                      onClick={() => setShowTermAndCondition(true)}
                      style={{
                        color: "#00ffed",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      click here
                    </span>
                  </PolicyDescription>
                </FormGroup>
                <Button
                  hidden={!showOtp}
                  block
                  className="btn-custom btn-round"
                  onClick={() => onVerify()}
                  disabled={!isCheck || !otp}
                  color="success"
                >
                  Validate OTP
                </Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="text-center footer-copyright">
              <div className="text-xs">© COPYRIGHT 2024 Two Trees Ventures</div>
              <div className="text-xs">Pvt Ltd- All Rights Reserved</div>
            </Col>
          </Row>
        </Container>
        <PopUp
          visible={showTermAndCondition}
          onOk={() => {
            setShowTermAndCondition(false);
          }}
          title={"Terms And Condition"}
          onCancel={() => setShowTermAndCondition(false)}
        >
          <PopupWrapper>
            <div style={{ textAlign: "left" }}>
              <div>
                {" "}
                1. Website Description Wnnr.in is an online platform offering
                users opportunities to participate in various gaming activities
                and earn rewards. By engaging in specified digital actions,
                users can earn tickets and points which can be redeemed for
                various prizes, including entry into raffles and other
                competitions.{" "}
              </div>
              <div>
                2. User Eligibility To use Wnnr.in, you must: - Be a resident of
                India. - Be at least 18 years old. - Possess a valid PAN card
                and Aadhar card for verification purposes.
              </div>{" "}
              <div>
                3. Acceptance of Terms By accessing and using Wnnr.in, you agree
                to comply with and be bound by these Terms and Conditions. If
                you do not agree with any part of these terms, you must not use
                the website.
              </div>
              <div>
                4. User Accounts - **Account Creation**: To participate in
                activities on Wnnr.in, you must create an account and provide
                accurate, complete information. - **Account Security**: You are
                responsible for maintaining the confidentiality of your account
                information and for all activities that occur under your
                account. - **Account Termination**: Wnnr.in reserves the right
                to terminate or suspend your account at any time for any reason,
                including violations of these terms.
              </div>
              <div>
                5. User Responsibilities - **Accurate Information**: Provide
                accurate and current information during registration and keep
                your profile information up to date. - **Lawful Use**: Use the
                platform only for lawful purposes and in accordance with these
                terms. - **Prohibited Activities**: Do not engage in fraudulent
                activities, use multiple accounts, or exploit the platform in
                any unauthorized manner.
              </div>
              <div>
                6. Intellectual Property - **Ownership**: All content, features,
                and functionality on Wnnr.in are the exclusive property of Two
                Trees Ventures Pvt Ltd or its licensors. - **Usage Rights**:
                Users are granted a limited, non-exclusive license to use the
                platform for personal, non-commercial use, subject to these
                terms.
              </div>
              <div>
                7. Disclaimer of Warranties Wnnr.in is provided on an "as is"
                and "as available" basis. We make no warranties or
                representations of any kind, express or implied, regarding the
                operation or availability of the website, or the information,
                content, or materials included on the website.
              </div>
              <div>
                8. Limitation of Liability In no event shall Wnnr.in, its
                directors, employees, or affiliates be liable for any direct,
                indirect, incidental, special, or consequential damages arising
                out of or in any way connected with the use of or inability to
                use the website or for any content obtained through the website.
              </div>
              <div>
                9. Indemnification You agree to indemnify, defend, and hold
                harmless Wnnr.in, its officers, directors, employees, and
                affiliates from any claims, liabilities, damages, losses, or
                expenses arising out of or in any way connected with your access
                to or use of the website.
              </div>
              <div>
                10. Governing Law (India) These terms and conditions shall be
                governed by and construed in accordance with the laws of India,
                without regard to its conflict of law principles.
              </div>
              <div>
                11. Dispute Resolution - **Informal Resolution**: In the event
                of any dispute, you agree to first attempt to resolve the
                dispute informally by contacting support@wnnr.in. -
                **Arbitration**: If the dispute cannot be resolved informally,
                it shall be resolved by binding arbitration in accordance with
                the Arbitration and Conciliation Act, 1996. The arbitration
                shall be conducted in Bangalore, India, and the language of
                arbitration shall be English.a By using Wnnr.in, you acknowledge
                that you have read, understood, and agree to be bound by these
                Terms and Conditions. For any questions or concerns, please
                contact support@wnnr.in
              </div>
            </div>
          </PopupWrapper>
        </PopUp>
      </div>
    </>
  );
};

export default Login;
