import React from "react";

export enum VARIANTS {
  PRIMARY = "PRIMARY",
  OUTLINE = "OUTLINE",
}

export type ButtonType = {
  variant?: VARIANTS;
  children: React.ReactNode;
  icon?: React.ReactNode;
} & React.ComponentPropsWithoutRef<"button">;
