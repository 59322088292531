import React from "react";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
export default function FeatureComingSoon() {
  return (
    <div
      style={{
        maxWidth: "280px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontSize: 28,
          textAlign: "center",
          fontWeight: 700,
          lineHeight: "25px",
          width: "70%",
        }}
      >
        This feature will be availabe soon!
      </div>
      <img
        style={{ display: "block", margin: "auto", marginTop: 24 }}
        src={DashboardConstant.WnnrLogo}
        width={100}
        height={100}
      />
    </div>
  );
}
