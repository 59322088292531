import PopUp, { SizeType } from "@components/PopUp";
import { Errors } from "@const/error";
import { getMilestoneById, submitAnswers } from "@network/Questions";
import {
  QuestionParams,
  QuestionReqParams,
  QuestionResData,
} from "@network/Questions/type";
import { updateNotice } from "@store/noticeReducer";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as S from "../style";
import * as _ from "lodash";
import { USER_ANSWER_FOR } from "@type/index";
import QuestionContainer from "./QuestionContainer";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@const/routes";
import { RaffleDetailType } from "@type/Raffle";
import img from "@assets/img";

export const Paragraph = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
`;

export const QuestionaryPopup = ({
  isOpen,
  onDismiss,
  onFinish,
  raffleId,
  milestoneId,
  refetchRaffleDetail,
  raffleDetail,
}: {
  isOpen: boolean;
  onDismiss: () => void;
  onFinish?: () => void;
  raffleId: number;
  milestoneId?: number;
  raffleDetail?: RaffleDetailType;
  refetchRaffleDetail: any;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState<QuestionResData>();
  const [stepAnswer, setStepAnswer] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [questions, setQuestions] = useState<QuestionReqParams[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [isOpenCompletedAlert, setIsOpenCompletedAlert] = useState(false);
  const [ticketCreated, setTicketCreated] = useState(false);
  const { id } = useParams();

  const onCancel = () => {
    setQuestions([]);
    onDismiss();
  };

  const handleChangeAnswer = useCallback(
    (questionId: number, answerIds: string[]) => {
      const index = _.findIndex(questions, ["id", questionId]);
      if (index !== -1) questions.splice(index, 1);
      if (answerIds?.length) questions.push({ id: questionId, answerIds });
      setQuestions(questions);
      setDisabled(_.size(questionData?.questions) !== _.size(questions));
    },
    [questions, questionData]
  );

  const onSubmitQuestionForm = async () => {
    if (disabled || !questions || !milestoneId) return;
    const params: QuestionParams = {
      milestoneId,
      questions: questions,
      refId: raffleId,
      userAnswerFor: USER_ANSWER_FOR.RAFFLE,
    };

    try {
      const res = await submitAnswers(params);
      if (res.data.submitAnswer) {
        setIsOpenCompletedAlert(true);
        setTicketCreated(res.data.ticketCreated);
        refetchRaffleDetail(id);
      } else {
        updateNotice(
          {
            type: "ERROR",
            content: Errors.SUBMIT,
          },
          dispatch
        );
      }
    } catch (error) {
      console.error(error);
      updateNotice(
        {
          type: "ERROR",
          content: Errors.SUBMIT,
        },
        dispatch
      );
    }
  };
  useEffect(() => {
    async function getQuestions() {
      if (!raffleId) return;
      const res = await getMilestoneById(raffleId);
      setQuestionData(res.data);
      setStepAnswer(res.data?.questions?.length - 1 || 0);
    }
    getQuestions();
  }, [raffleId]);

  return (
    <>
      <PopUp
        visible={isOpen && !isOpenCompletedAlert}
        title={
          `${questionData?.title} 0${currentStep + 1} of 0${stepAnswer + 1}` ||
          ""
        }
        footer={false}
        onOk={onSubmitQuestionForm}
        onCancel={onCancel}
        okText="Submit"
        size={SizeType.LARGE}
        isOnlyOkButton
        isDisabledOk={disabled}
      >
        <S.Content>
          <p className="note_text">
            <span className="note_text-red">*</span> Please complete the
            questions to win a free raffle ticket!
          </p>
          <QuestionContainer
            totalStep={stepAnswer}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            question={questionData?.questions?.[currentStep]}
            index={currentStep}
            onSubmitQuestionForm={onSubmitQuestionForm}
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
            handleChangeAnswer={handleChangeAnswer}
          />
        </S.Content>
      </PopUp>
      <PopUp
        title={`${raffleDetail?.title} - Completed!`}
        visible={isOpenCompletedAlert}
        onCancel={onDismiss}
      >
        <S.PopUpContent>
          <S.JoinQuizWrapper>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <S.JoinQuizImg src={img.Mascot_7} />
                <S.CongratulationTitle>
                  <>
                    <S.QuizLogo src={DashboardConstant.WnnrLogo} />
                    <S.NotificationTitle>
                      {ticketCreated
                        ? "Congratulations!"
                        : "Thank you for your participation! "}
                    </S.NotificationTitle>
                    <S.NotificationDes>
                      {ticketCreated
                        ? "You have won a free ticket to win a prize!"
                        : "You have got 3 tickets today!"}
                    </S.NotificationDes>
                  </>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <S.Button
                      color="linear-gradient(to right, #8c52ff, #5ce1e6)"
                      onClick={() => navigate(routes.ticket)}
                    >
                      See Ticket
                    </S.Button>
                    <S.Button
                      color="linear-gradient(to right, #ff3131, #ff914d)"
                      onClick={() => onFinish?.()}
                    >
                      Play Raffle
                    </S.Button>
                  </div>
                </S.CongratulationTitle>
              </div>
              <S.NotificationWarning>
                <S.WarningSymbol>*</S.WarningSymbol>
                <S.NotificationWarningText>
                  Not all tickets can be used for all types of rafflles. This
                  ticket ensures a free entry into a daily raffle of your
                  choice, Ticket expires in 72 hrs.
                </S.NotificationWarningText>
              </S.NotificationWarning>
            </div>
          </S.JoinQuizWrapper>
        </S.PopUpContent>
      </PopUp>
    </>
  );
};
