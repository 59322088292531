import React from "react";

import * as S from "./style";
import Img from "@assets/img";
import CountdownTimer from "@components/CountdownTimer";
import { Raffle } from "@type/Raffle";
import { Link } from "react-router-dom";
import { API_END_POINTS } from "@utils/EndPoint";

interface Props {
  title?: string;
  raffle?: Raffle;
  buttonText?: string;
  blank?: string;
}

const DashboardBox = ({ title, raffle, buttonText, blank }: Props) => {
  return (
    <S.Wrapper>
      <S.Robot>
        <img src={Img.Robot} />
      </S.Robot>
      <S.Info>
        <h1>{title}</h1>
        {blank && <span>{blank}</span>}
        {raffle?.startTime && <CountdownTimer time={raffle?.startTime} />}
        {buttonText && (
          <Link to={`${API_END_POINTS.RAFFLE_PARTICIPANTS}/${raffle?.id}`}>
            {buttonText}
          </Link>
        )}
      </S.Info>
    </S.Wrapper>
  );
};

export default DashboardBox;
