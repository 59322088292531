export const Errors: { [k: string]: any } = {
  LIMIT_RESEND_OTP: "You can only receive maximum 5 OTP a day",
  OTP_NOT_EXIST_OR_EXPIRE: "OTP not exist or expired!",
  SEND_OTP_ERROR:
    "Sending OTP failed, please check your phone number and try again!",
  VERIFY_OTP_ERROR:
    "Verify OTP failed, please check your otp number and try again!",
  USERNAME_EXISTED: "Username is exist, please try again!",
  PROFILE_UPDATED_ERROR: "Update ptofile fail, please try again!",
  MAXIMUM_LUCKY_NUMBER_CAN_SELECTED: "Maximum lucky number can select",
  UNKNOWN: "Unknown Error!",
  SUBMIT: "Submit failed!",
};
