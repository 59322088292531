import React, { useState } from "react";
import { Alert, Container } from "reactstrap";

function SectionNotifications() {
  const [alertSuccess, setAlertSuccess] = useState(true);
  const [alertWarning, setAlertWarning] = useState(true);
  const [alertDanger, setAlertDanger] = useState(true);

  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3>Notifications</h3>
        </div>
      </Container>
      <div id="notifications">
        <Alert color="info">
          <Container>
            <span>This is a plain notification!</span>
          </Container>
        </Alert>
        <Alert color="success" isOpen={alertSuccess} toggle={() => setAlertSuccess(false)}>
          <Container>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setAlertSuccess(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <span>This is a notification with a close button.</span>
          </Container>
        </Alert>
        <Alert color="warning" isOpen={alertWarning} toggle={() => setAlertWarning(false)}>
          <Container>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setAlertWarning(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="alert-wrapper">
              <div className="message">
                <i className="nc-icon nc-bell-55" /> This is a notification with a close button and an icon.
              </div>
            </div>
          </Container>
        </Alert>
        <Alert color="danger" isOpen={alertDanger} toggle={() => setAlertDanger(false)}>
          <Container>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => setAlertDanger(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="alert-wrapper">
              <div className="message">
                <i className="nc-icon nc-bell-55" /> This is a notification with a close button, an icon, and multiple lines of text. You can see that the icon and the close button are always vertically aligned. This is a beautiful notification, so you don't have to worry about the style.
              </div>
            </div>
          </Container>
        </Alert>
      </div>
    </>
  );
}

export default SectionNotifications;
